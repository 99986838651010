import be from './be-main' //后端功能模块
import HiLaw from './be-HiLaw' //业务逻辑模块
import prompt from './prompt' //prompt模块

const graph = new Object();

const protot = (question,inputType,options,links,callback) => {
    return({
        question: question,
        inputType: inputType,
        options: options,
        links:links,
        callback:callback
    })
}

//接口定义：
//default：{0:是，1:否}

//——————V1.4——————

//init
graph.init = () => {
    return(protot('请问您的需求是?','multi-select',['帮我判断是否能获得补偿','帮我计算补偿金额','给我提供维权策略'],{
        'any':['s30001']
    },undefined))
}

graph.s30001 = () => {
    return(protot('请问您是否签了劳动合同?','select','default',{
        '0':['s30002']
    },(index,event)=>{
        console.log('index:',index)
        if(index === 1){
            event.set_safe_hight(null)
            event.setInputable(true)
            event.set_renderQR(null)
            event.setTyping(true);
            event.val = '没签合同'
            be.main(event)
        }
    }))
}

graph.s30002 = () => {
    return(protot('请问谁提出希望解除劳动合同?','select',['我想辞职','我被辞退'],{
        '0':['s30003'],
        '1':['s30004']
    },(index,event)=>{
        if(index === 0){
            HiLaw.index_2(event,'employee',1.9,'null')//index = 3.1（个人解除）//'自愿辞职'
        }else if(index === 1){
            HiLaw.index_2(event,'employer',2,'null')//index = 3.2（单位解除
        }
    }))
}

const node_temp = () => {
    
}

graph.s30003 = () => {
    return(protot('请问您希望解除劳动合同的理由是?','select',['单位欠薪','强制加班','调岗/降薪','合同问题'],null,(caseIndex,event)=>{
        event.set_safe_hight(null)
        event.setInputable(true)
        event.set_renderQR(null)
        //——————获取聊天列表——————
        let current_messages_index = event.messages.length
        console.log('current_messages_index:',current_messages_index)
        event.set_mibce(current_messages_index+2)
        console.log('event.mibce:',event.mibce)
        //——————————————————————
        if(caseIndex === 0){
            HiLaw.index_3(event,'单位欠薪',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_1)
        }else if(caseIndex === 1){
            HiLaw.index_3(event,'强制加班',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_2)
        }else if(caseIndex === 2){
            HiLaw.index_3(event,'调岗/降薪',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_3)
        }else if(caseIndex === 3){
            HiLaw.index_3(event,'合同问题',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_4)
        }
        // else if(caseIndex === 4){
        //     HiLaw.index_3(event,'其他原因/自愿辞职',prompt.step_4dot1.law_02,prompt.step_4dot1.guidance_5)
        // }
    }))
}



graph.s30004 = () => {
    return(protot('请问用人单位提出解除劳动合同的理由是？', 'multi-select', ['绩效不合格', '工作过失', '合同期满', '单位裁员', '身体原因', '试用期内', '没有原因'], null, (caseIndex,event)=>{
        //——————获取聊天列表——————
        let current_messages_index = event.messages.length
        console.log('current_messages_index:',current_messages_index)
        event.set_mibce(current_messages_index+2)
        console.log('event.mibce:',event.mibce)
        //———————————————————————
        if(caseIndex === 0){
            HiLaw.index_3(event,'绩效不合格',prompt.step_4dot2.law_01,prompt.step_4dot2.guidance_1)
        }else if(caseIndex === 1){
            HiLaw.index_3(event,'工作过失',prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_2)  
        }else if(caseIndex === 2){
            HiLaw.index_3(event,'合同期满',prompt.step_4dot2.law_03,prompt.step_4dot2.guidance_3)   
        }else if(caseIndex === 3){
            HiLaw.index_3(event,'单位裁员',prompt.step_4dot2.law_04,prompt.step_4dot2.guidance_4)  
        }else if(caseIndex === 4){
            HiLaw.index_3(event,'身体原因',prompt.step_4dot2.law_05,prompt.step_4dot2.guidance_5)      
        }else if(caseIndex === 5){
            HiLaw.index_3(event,'试用期内',prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_6)       
        }
        // else if(caseIndex === 6){
        //     HiLaw.index_3(event,'单位39理由',prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_7)        
        // }else if(caseIndex === 7){
        //     HiLaw.index_3(event,'单位40理由',prompt.step_4dot2.law_01,prompt.step_4dot2.guidance_8)      
        // }
        else if(caseIndex === 8){//无理由/违法理由
            HiLaw.index_3(event,'没有原因',prompt.step_4dot2.law_06,prompt.step_4dot2.guidance_9)    
        }
    }))
}

// graph.s30003 = () => {
//     return(protot('请问您希望解除劳动合同的理由是?','select',['单位欠薪','强制加班','调岗/降薪','合同问题'],{
//         'any':['e20001'],
//         '0':['f10001'],
//         '1':['f10006','f10005'],
//         '2':['f10010','f10012','f10008'],
//         '3':['f10015','f10014']
//     },undefined))
// }

// graph.s30004 = () => {
//     return(protot('请问用人单位提出解除劳动合同的理由是？', 'multi-select', ['绩效不合格', '工作过失', '合同期满', '单位裁员', '身体原因', '试用期内', '没有原因'], {
//         'any': ['f10017'],
//         'any': ['f10018'],
//         'any': ['e20001'],
//         'any': ['s30005'],
//         '绩效不合格': ['f10020'],
//         '绩效不合格': ['f10022'],
//         '绩效不合格': ['f10023'],
//         '绩效不合格': ['e20010'],
//         '工作过失': ['f10024'],
//         '合同期满': ['f10026'],
//         '单位裁员': ['f10030'],
//         '单位裁员': ['f10031'],
//         '身体原因': ['f10032'],
//         '试用期内': ['f10036']
//     }, undefined))
// }


// graph.s30003 = () => {
//     return(protot('请问您希望解除劳动合同的理由是？', 'multi-select', ['单位欠薪', '强制加班', '调岗/降薪', '合同问题'], {
//         'any': ['e20001'],
//         '单位欠薪': ['f10001'],
//         '强制加班': ['f10005'],
//         '强制加班': ['f10006'],
//         '调岗/降薪': ['f10008'],
//         '调岗/降薪': ['f10010'],
//         '调岗/降薪': ['f10012'],
//         '调岗/降薪': ['e20008'],
//         '合同问题': ['f10014'],
//         '合同问题': ['f10015']
//     }, undefined))
// }


graph.e20001 = () => {
    return(protot('您是否有劳动合同的原件？', 'select', 'default', {
        '1': ['e20002']
    }, undefined))
}


graph.f10001 = () => {
    return(protot('您单位的欠薪的时长是？', 'string', 'default', {
        '2': ['f10002'],
        '2': ['e20004']
    }, undefined))
}


graph.f10002 = () => {
    return(protot('请问您是否已经向单位提出尽快发放欠薪的要求？', 'select', 'default', {
        '0': ['e20003']
    }, undefined))
}


graph.f10003 = () => {
    return(protot('单位是否提出或已经给予赔偿金（及金额）？', 'select', 'default', {
        '0': ['f10004']
    }, undefined))
}


graph.f10005 = () => {
    return(protot('您是否明确拒绝过加班？', 'select', 'default', {
        '0': ['e20007']
    }, undefined))
}


graph.f10006 = () => {
    return(protot('单位是否支付过加班费？', 'select', 'default', {
        '0': ['f10007']
    }, undefined))
}


graph.f10008 = () => {
    return(protot('单位是否向您解释过调岗降薪的原因', 'select', 'default', {
        '0': ['f10009']
    }, undefined))
}


graph.f10010 = () => {
    return(protot('单位的规章制度中是否有关于调岗降薪的规定？', 'select', 'default', {
        '0': ['f10011']
    }, undefined))
}


graph.f10012 = () => {
    return(protot('调岗降薪前后，您薪资的变化情况是？', 'string', 'default', {
        'any': ['f10013']
    }, undefined))
}


graph.f10013 = () => {
    return(protot('调整后的薪资是否低于当地的最低工资？', 'select', 'default', {
        '0': ['a40002']
    }, undefined))
}


graph.f10015 = () => {
    return(protot('您是否和单位协商了修改劳动合同？', 'select', 'default', {
        '0': ['f10016'],
        '1': ['a40001']
    }, undefined))
}


graph.f10018 = () => {
    return(protot('单位是否提出或已经给予赔偿金？', 'select', 'default', {
        '0': ['f10019']
    }, undefined))
}


graph.f10020 = () => {
    return(protot('单位的绩效规定是否规定在单位规章中？', 'select', 'default', {
        '0': ['f10021']
    }, undefined))
}


graph.f10023 = () => {
    return(protot('根据您的工作表现，您认为自己是否能够胜任工作？', 'select', 'default', {
        '0': ['e20009']
    }, undefined))
}


graph.f10024 = () => {
    return(protot('您工作中的行为是否造成了公司的损失？', 'select', 'default', {
        '0': ['f10025']
    }, undefined))
}


graph.f10025 = () => {
    return(protot('造成了公司的损失大小如何？', 'string', 'default', {
        'any': ['e20011']
    }, undefined))
}


graph.f10026 = () => {
    return(protot('您在本单位工作了多久？', 'string', 'default', {
        'any': ['f10027']
    }, undefined))
}


graph.f10027 = () => {
    return(protot('您和公司签订了几次合同，分别是定期合同还是不定期合同？', 'string', 'default', {
        'any': ['f10028']
    }, undefined))
}


graph.f10028 = () => {
    return(protot('最近一次合同约定的合同期时长是多久，何时到期？', 'string', 'default', {
        'any': ['f10029']
    }, undefined))
}


graph.f10032 = () => {
    return(protot('您的身体问题/状况是否对工作有重大影响', 'select', 'default', {
        '0': ['e20012'],
        '1': ['f10033']
    }, undefined))
}


graph.f10033 = () => {
    return(protot('您出现身体问题原因是？', 'select', ['工伤', '其他原因'], {
        'any': ['f10034'],
        '工伤': ['e20014']
    }, undefined))
}


graph.f10034 = () => {
    return(protot('您是否因为这一身体问题请过病假？', 'select', 'default', {
        'any': ['f10035']
    }, undefined))
}


graph.f10035 = () => {
    return(protot('您请了多久的病假？', 'string', 'default', {
        '0': ['e20013']
    }, undefined))
}


graph.f10036 = () => {
    return(protot('您与单位签订了多久的合同？', 'string', 'default', {
        'any': ['f10037']
    }, undefined))
}


graph.f10037 = () => {
    return(protot('劳动合同规定了多久的试用期？', 'string', 'default', {
        'any': ['f10038']
    }, undefined))
}


graph.f10038 = () => {
    return(protot('单位是否向您解释了不符合录用条件的原因？', 'select', 'default', {
        '0': ['f10039']
    }, undefined))
}


graph.e20002 = () => {
    return(protot('请问您是否其他可以证明签署过劳动合同，或劳动关系存在的证据？', 'multi-select', ['没有', '劳动合同照片、复印件等', '工卡，工服，考勤记录，工资单等'], {
    }, undefined))
}


graph.e20003 = () => {
    return(protot('请问您是否有向单位提出尽快发放欠薪的要求的证据，如聊天记录等？', 'select', 'default', {
    }, undefined))
}


graph.e20004 = () => {
    return(protot('请问您是否有证据证明单位已经欠薪，如长期银行流水等？', 'select', 'default', {
    }, undefined))
}


graph.e20005 = () => {
    return(protot('是否有工卡，工服，餐卡，工作时的聊天记录等证据能够证明您确实（曾）在单位工作过？', 'select', 'default', {
    }, undefined))
}


graph.e20006 = () => {
    return(protot('是否有银行转账记录，微信或支付宝转账记录，支票等证据能够证明单位（曾）给您了报酬？', 'select', 'default', {
    }, undefined))
}


graph.e20007 = () => {
    return(protot('您是否有证据证明您明确拒绝了加班？', 'select', 'default', {
    }, undefined))
}


graph.e20008 = () => {
    return(protot('您是否有证据证明单位向您表达了调岗降薪，包括人事部门通知，微信/办公软件聊天记录等', 'select', 'default', {
    }, undefined))
}


graph.e20009 = () => {
    return(protot('您是否有证据能够证明您可以胜任工作，如绩效记录，工作邮件，聊天记录等？', 'select', 'default', {
    }, undefined))
}


graph.e20010 = () => {
    return(protot('您是否有单位向您发出的绩效不合格通知，邮件等', 'select', 'default', {
    }, undefined))
}


graph.e20011 = () => {
    return(protot('您的单位是否掌握了能够支持您的行为造成了公司损失的证据', 'select', 'default', {
    }, undefined))
}


graph.e20012 = () => {
    return(protot('您是否能够开具健康体检报告等证明您的身体足以胜任工作？', 'select', 'default', {
    }, undefined))
}


graph.e20013 = () => {
    return(protot('您是否有证据证明您已经请假且单位已经准假？', 'select', 'default', {
    }, undefined))
}


graph.e20014 = () => {
    return(protot('您是否已经进行了工伤认定，并有相关报告文件', 'select', 'default', {
    }, undefined))
}


graph.f10004 = () => {
    return(protot('单位提出了多少赔偿金？', 'string', 'default', {
    }, undefined))
}


graph.f10007 = () => {
    return(protot('单位支付过多少加班费？', 'string', 'default', {
    }, undefined))
}


graph.f10009 = () => {
    return(protot('单位向您解释过调岗降薪的原因是？', 'string', 'default', {
    }, undefined))
}


graph.f10011 = () => {
    return(protot('单位的规章制度中关于调岗降薪是如何规定的？', 'string', 'default', {
    }, undefined))
}


graph.f10014 = () => {
    return(protot('劳动合同中具体存在哪些问题/不合理的条款？', 'string', 'default', {
    }, undefined))
}


graph.f10016 = () => {
    return(protot('协商的结果如何，单位是否同意修改？', 'string', 'default', {
    }, undefined))
}


graph.f10017 = () => {
    return(protot('单位是否提前通知您需要解除劳动关系？', 'select', 'default', {
    }, undefined))
}


graph.f10019 = () => {
    return(protot('单位提出的解约赔偿金的金额为？', 'string', 'default', {
    }, undefined))
}


graph.f10021 = () => {
    return(protot('绩效规定是否经过了公示？', 'select', 'default', {
    }, undefined))
}


graph.f10022 = () => {
    return(protot('单位在绩效低/不合格后是否进行了培训或调整岗位？', 'select', 'default', {
    }, undefined))
}


graph.f10029 = () => {
    return(protot('您离预计退休年龄还有多久？', 'string', 'default', {
    }, undefined))
}


graph.f10030 = () => {
    return(protot('单位是否提前30日向工会或者全体职工说明情况，听取工会或者职工的意见？', 'select', 'default', {
    }, undefined))
}


graph.f10031 = () => {
    return(protot('您是否属于法律规定的需要优先留用的人员？', 'select', 'default', {
    }, undefined))
}


graph.f10039 = () => {
    return(protot('单位解释的不符合录用条件的原因是？', 'string', 'default', {
    }, undefined))
}


graph.s30005 = () => {
    return(protot('您是否处于不能被开除的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期，即将退休等）', 'select', 'default', {
    }, undefined))
}

export default graph