import llm from './be-llm'; //llm模块
import cdb from './be-cbd' //云数据库模块
import fe from './fe-utils' //前端功能模块
import prompt from './prompt' //prompt模块
import HiLaw from './be-HiLaw' //业务逻辑模块

const be = new Object();

be.doc_update = (value,event)=>{
    event.set_docID(value)
}

be.main = async function(event){
    
    console.log('event:',event)
    //第一步：判断合同情况
    if(event.step_index === 1){
        //fe.aiReply('null',prompt.step_1.guidance_1,event.appendMsg,event.setPlaceholder,event.setTyping,event.setInputable)
        //fe.addQR([event.defaultQR, event.setQR],['签过合同','没签合同'])
        //event.setIndex(event.step_index + 0.1)
    }else if(event.step_index === 1.1){
        function step_1dot1(caseIndex){ 
            event.defaultQR.splice(-2, 2); 
            //离职前后的问题
            if(caseIndex === 0){ 
                event.setIndex(1.2)
                fe.aiReply('null',prompt.step_1dot2.guidance_1,event)
                fe.addQR([event.defaultQR, event.setQR],['签过合同','没签合同'])
            }
            //在职期间的问题
            else if(caseIndex === 1){
                event.setIndex(event.step_index + 0.2)
                fe.comming_soon(event.appendMsg,event.setPlaceholder,event.setInputable)
            }
        }
        console.log('event.val:',event.val)
        if(event.val === '离职前后的问题'){
            step_1dot1(0)
        }else if(event.val === '在职期间的问题(开发中，待上线)'){
            step_1dot1(1)
        }else{
            let this_context = prompt.step_1.guidance_1
            let this_options = ['离职前后的问题','在职期间的问题']
            await llm.format(event.val,this_options,this_context,function(output){
                if(output === 0||output === '0'){
                    step_1dot1(0)
                }else if(output === 1||output === '1'){
                    step_1dot1(1)
                }else{
                    fe.llmFreeStyleReply(output,event.appendMsg,event.setTyping)
                }
            })        
        }
        console.log(event.this_case) 
    }
    //离职前后的问题
    else if(event.step_index === 1.2){
        function step_1dot2(caseIndex){ 
            //event.defaultQR.splice(-2, 2); 
            //没签合同
            if(caseIndex === 0){
                HiLaw.index_1dot2(event,'no','no_contract',4,prompt.step_1dot2.law_no_contract,prompt.step_1dot2.guidance_no_contract,()=>{
                    return
                })
            }
            //签过合同
            else if(caseIndex === 1){
                HiLaw.index_1dot2(event,'yes',undefined,2,'null',prompt.step_2.guidance_1,()=>{
                    fe.addQR([event.defaultQR, event.setQR],['我想辞职','我被辞退'])
                })
            }
        }
        if(event.val === '没签合同'){
          step_1dot2(0)
        }else if(event.val === '签过合同'){
          step_1dot2(1)
        }else{
            let this_context = prompt.step_1dot2.guidance_1
            let this_options = ['没签合同','签过合同']
            await llm.format(event.val,this_options,this_context,function(output){
                if(output === 0||output === '0'){
                    step_1dot2(0)
                }else if(output === 1||output === '1'){
                    step_1dot2(1)
                }else{
                    fe.llmFreeStyleReply(output,event.appendMsg,event.setTyping)
                }
            })        
        }     
        console.log(event.this_case) 
    //第二步：有合同，判断提出解除的一方
    }else if(event.step_index === 2){
        function step_2 (caseIndex){//劳动者=0 单位=1
            event.defaultQR.splice(-2, 2);
            if(caseIndex === 0){
                HiLaw.index_2(event,'employee',1.1,'null',prompt.step_3.guidance_1,['单位欠薪','强制加班','调岗/降薪','合同问题'])//index = 3.1（个人解除）//'自愿辞职'
            }else if(caseIndex === 1){
                HiLaw.index_2(event,'employer',1.2,'null',prompt.step_3.guidance_2,['绩效不合格','工作过失','合同期满','单位裁员','身体原因','试用期内','没有原因'])//index = 3.2（单位解除）
            }
        }
        if(event.val === "我想辞职"){
            step_2(0)
        }else if(event.val === "我被辞退"){
            step_2(1)
        }else{
            let this_context = prompt.step_2.guidance_1
            let this_options = ['劳动者本人提出辞职','用人单位提出辞退']
            await llm.format(event.val,this_options,this_context,function(output){
                if(output === 0||output === '0'){             
                    step_2(0)
                }else if(output === 1||output === '1'){
                    step_2(1)
                }else{
                    fe.llmFreeStyleReply(output,event.appendMsg,event.setTyping)
                }
            })
        }
        console.log(event.this_case) 
    }
    //第三步，判断解除原因
    //个人提出解除      
    else if(event.step_index === 3.1){
        function step_3dot1(caseIndex){//个人无支持=0，合法=1 
            console.log(event.this_case)
            event.defaultQR.splice(-4, 4);
            //——————获取聊天列表——————
            let current_messages_index = event.messages.length
            console.log('current_messages_index:',current_messages_index)
            event.set_mibce(current_messages_index+2)
            console.log('event.mibce:',event.mibce)
            //——————上传对话——————
            let formatted_messages = fe.format_messages(event.val,event.messages,3)
            cdb.doc_update(event.doc_id,formatted_messages)
            //——————————————————————
            if(caseIndex === 0){
                HiLaw.index_3(event,'单位欠薪',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_1)
            }else if(caseIndex === 1){
                HiLaw.index_3(event,'强制加班',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_2)
            }else if(caseIndex === 2){
                HiLaw.index_3(event,'调岗/降薪',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_3)
            }else if(caseIndex === 3){
                HiLaw.index_3(event,'合同问题',prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_4)
            }else if(caseIndex === 4){
                HiLaw.index_3(event,'其他原因/自愿辞职',prompt.step_4dot1.law_02,prompt.step_4dot1.guidance_5)
            }
        }
        if(event.val === "单位欠薪"){
            step_3dot1(0)  
        }else if(event.val === "强制加班"){
            step_3dot1(1)          
        }else if(event.val === "调岗/降薪"){
            step_3dot1(2)          
        }else if(event.val === "合同问题"){
            step_3dot1(3)          
        }else if(event.val === "自愿辞职"){
            step_3dot1(4)          
        }else{
            HiLaw.index_3_llm = (event,'employee',(output_index)=>{
                if(output_index[0]<= 5){
                    let this_index = output_index[0] - 1
                    step_3dot1(this_index)  
                }else{
                    //——————待调整——————
                    console.log('error:',output_index)
                    fe.comming_soon(event.appendMsg,event.setPlaceholder,event.setInputable)
                    //—————————————————
                }
            })
        }
    }
    //单位提出解除
    else if(event.step_index === 3.2){
        function step_3dot2 (caseIndex){                 
            console.log(event.this_case)           
            event.defaultQR.splice(-7, 7);
            //——————获取聊天列表——————
            let current_messages_index = event.messages.length
            console.log('current_messages_index:',current_messages_index)
            event.set_mibce(current_messages_index+2)
            console.log('event.mibce:',event.mibce)
            //——————上传对话——————
            let formatted_messages = fe.format_messages(event.val,event.messages,3)
            cdb.doc_update(event.doc_id,formatted_messages)
            //——————————————————————
            if(caseIndex === 0){
                HiLaw.index_3(event,'绩效不合格',prompt.step_4dot2.law_01,prompt.step_4dot2.guidance_1)
            }else if(caseIndex === 1){
                HiLaw.index_3(event,'工作过失',prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_2)  
            }else if(caseIndex === 2){
                HiLaw.index_3(event,'合同期满',prompt.step_4dot2.law_03,prompt.step_4dot2.guidance_3)   
            }else if(caseIndex === 3){
                HiLaw.index_3(event,'单位裁员',prompt.step_4dot2.law_04,prompt.step_4dot2.guidance_4)  
            }else if(caseIndex === 4){
                HiLaw.index_3(event,'身体原因',prompt.step_4dot2.law_05,prompt.step_4dot2.guidance_5)      
            }else if(caseIndex === 5){
                HiLaw.index_3(event,'试用期内',prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_6)       
            }else if(caseIndex === 6){
                HiLaw.index_3(event,'单位39理由',prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_7)        
            }else if(caseIndex === 7){
                HiLaw.index_3(event,'单位40理由',prompt.step_4dot2.law_01,prompt.step_4dot2.guidance_8)      
            }else if(caseIndex === 8){//无理由/违法理由
                HiLaw.index_3(event,'没有原因',prompt.step_4dot2.law_06,prompt.step_4dot2.guidance_9)    
            }
        }
        if(event.val === "绩效不合格"){
            step_3dot2(0)   
        }else if(event.val === "工作过失"){
            step_3dot2(1)   
        }else if(event.val === "合同期满"){
            step_3dot2(2)  
        }else if(event.val === "单位裁员"){
            step_3dot2(3)   
        }else if(event.val === "身体原因"){
            step_3dot2(4)          
        }else if(event.val === "试用期内"){
            step_3dot2(5)          
        }else if(event.val === "没有原因"){
            step_3dot2(8)          
        }else{
            HiLaw.index_3_llm = (event,'employer',(output_index)=>{
                if(output_index[0]<= 6){
                    let this_index = output_index[0] - 1
                    step_3dot2(this_index)  
                }else if(output_index[0]<= 7){
                    step_3dot2(8)
                }else{
                    //——————待调整——————
                    console.log('error:',output_index)
                    fe.comming_soon(event.appendMsg,event.setPlaceholder,event.setInputable)
                    //—————————————————
                }
            })      
        }
    }
    //进入证据确认环节：4=未签合同，4.1=个人提出，4.2=单位提出，4.3=共同协商
    else if(event.step_index >= 4 && event.step_index < 5 ){
        console.log('——————证据确认——————')
        //——————未签合同——————
        if (event.this_case.termination_reason === 'no_contract'){
            console.log('未签合同 证据确认')
            HiLaw.index_4(event,8,'no_contract',prompt.step_5.prompt_1,prompt.step_1dot2.law_no_contract,prompt.step_1dot2.guidance_no_contract)
            //测试用例：没有补偿金，有工卡作为工作记录，有银行转账作为工资支付记录
        }
        //——————劳动者提出——————
        //['单位欠薪','强制加班','调岗/降薪','合同问题','自愿辞职']
        else if(event.this_case.proposing_party === 'employee'){
            if(event.this_case.termination_reason === '单位欠薪'){//unpaid_wages
                HiLaw.index_4(event,event.mibce,'employee_1',prompt.step_5.prompt_2,prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_1)
                //测试用例：1. 有；2.每月15日，欠薪2个月；3. 有
            }else if(event.this_case.termination_reason === '强制加班'){
                HiLaw.index_4(event,event.mibce,'employee_2',prompt.step_5.prompt_2,prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_2)
            }else if(event.this_case.termination_reason === '调岗/降薪'){
                HiLaw.index_4(event,event.mibce,'employee_3',prompt.step_5.prompt_2,prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_3)
                //测试用例：1. 有；2. 没有；3. 没有 4. 没有低于；5. 有聊天记录和打卡记录
            }else if(event.this_case.termination_reason === '合同问题'){
                HiLaw.index_4(event,event.mibce,'employee_4',prompt.step_5.prompt_2,prompt.step_4dot1.law_01,prompt.step_4dot1.guidance_4)
            }
            else if(event.this_case.termination_reason === '自愿辞职'){
                fe.comming_soon(event.appendMsg,event.setPlaceholder,event.setInputable)
                //HiLaw.index_4(event,event.mibce,'employee_1',,,)
                // let this_prompt = prompt.step_5
                // let this_law = prompt.step_4dot1
                // let this_context = prompt.step_4dot1
            }
        }
        //——————单位提出——————
        //['绩效不合格','工作过失','合同期满','单位裁员','身体原因','试用期内','没有原因']
        else if(event.this_case.proposing_party === 'employer'){
            if(event.this_case.termination_reason === '绩效不合格'){
                HiLaw.index_4(event,event.mibce,'employer_1',prompt.step_5.prompt_3,prompt.step_4dot2.law_01,prompt.step_4dot2.guidance_1)
            }else if(event.this_case.termination_reason === '工作过失'){
                HiLaw.index_4(event,event.mibce,'employer_2',prompt.step_5.prompt_4,prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_2)
            }else if(event.this_case.termination_reason === '合同期满'){
                HiLaw.index_4(event,event.mibce,'employer_3',prompt.step_5.prompt_5,prompt.step_4dot2.law_03,prompt.step_4dot2.guidance_3)
            }else if(event.this_case.termination_reason === '单位裁员'){
                HiLaw.index_4(event,event.mibce,'employer_4',prompt.step_5.prompt_6,prompt.step_4dot2.law_04,prompt.step_4dot2.guidance_4)
            }else if(event.this_case.termination_reason === '身体原因'){
                HiLaw.index_4(event,event.mibce,'employer_5',prompt.step_5.prompt_9,prompt.step_4dot2.law_05,prompt.step_4dot2.guidance_5)
            }else if(event.this_case.termination_reason === '试用期内'){
                HiLaw.index_4(event,event.mibce,'employer_6',prompt.step_5.prompt_8,prompt.step_4dot2.law_02,prompt.step_4dot2.guidance_6)
            }else if(event.this_case.termination_reason === '没有原因'){
                HiLaw.index_4(event,event.mibce,'employer_7',prompt.step_5.prompt_10,prompt.step_4dot2.law_06,prompt.step_4dot2.guidance_9)
            }
        }
    }else if(event.step_index>=5){
        fe.comming_soon(event.appendMsg,event.setPlaceholder,event.setInputable)
    }
}

export default be