import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Chat, { Bubble, Stepper, Step, Card, Divider } from 'chatui-core';
import 'chatui-core/es/styles/index.less';
import 'chatui-core/dist/index.css';
import './chatui-theme.css';
import './index.css';
import fe from './fe-utils' //前端功能模块
import be from './be-main' //后端功能模块
import cdb from './be-cbd' //云数据库模块
import { HiLaw_CheckList, HiLaw_Collapse_1, HiLaw_Collapse_2, HiLaw_PayCard, HiLaw_DatePicker, HiLaw_Green_Notice, HiLaw_Yellow_Notice, HiLaw_Red_Notice, HiLaw_Options_Popup_2 } from './fe-component';//自定义组件
import cloudbase from "@cloudbase/js-sdk"
//临时方案，用于解决setTyping is not a function的报错
import { useMessages } from '@chatui/core';
import be_vf from './be-vf'

let doc_init = false//初始化状态

//APP主函数
const App = () => {
  //——————暂时弃用的V1.3状态——————
  //默认快捷短语 QR = QuickReplies
  const [defaultQR, setQR] = useState([
    // {
    //   icon: 'compass',
    //   name: '流程指南',
    // }
  ]); 
  //用户需求选项
  //const [user_requ, set_user_requ] = useState([false,false,false]);
  //用户设备类型
  //const [device_type,setdevice_type] = 'unknow'

  //——————V1.4沿用的状态——————
  //流程管理
  const [step_index, setIndex] = useState(1.2);    
  //messages_index_before_confirm_evidence
  const [mibce, set_mibce] = useState(0);
  //设定案件步骤hook
  const [this_case, set_case] = useState({ 
    contract: undefined, 
    proposing_party:undefined,
    termination_reason:undefined,
  });
  //——————案件参数解释——————
  //合同是否签署：no（未签）/yes（已签）
  //是否已经解除：not_dissolved（未解除）/dissolved（已解除）
  //提出解除的一方：employee（雇员）/employer（雇主）/consult（协商）
  //解除的情况：
  //没有合同: no_contract
  //单位解除类型：employer_0（无理由）/employer_1（39理由）/employer_2（40理由）/employer_3（41理由）/employer_4（违法理由）
  //劳动者解除类型：employee_not_protected（个人无支持理由）/employee_protected（个人合法理由）
  //协商解除类型：negotiate_byEmployee（个人提出协商）/negotiate_byEmployer（单位提出协商）
  //——————————————————————


  //——————V2.0沿用的状态——————
  //获取消息列表DOM引用
  const msgRef = React.useRef(null);
  window.msgRef = msgRef;
  //初始化消息
  const initialMessages = []; 
  //聊天状态初始化
  const { messages, appendMsg, setTyping } = useMessages(initialMessages); 
  //输入框占位符初始化
  const [placeholder, setPlaceholder] = useState("请简要描述您遇到的纠纷情况")
  //消息初始化
  const [init_state, setInit] = useState(false);  
  //禁用输入框参数
  const [inputable, setInputable] = useState(false);
  //——————云开发初始化——————
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  const db = app.database();
  const collection = db.collection(process.env.REACT_APP_CASE_DB); 

  const [user_id, set_user_id] = useState(null);

  //——————初始消息&云数据库初始化——————
  const [doc_id, set_docID] = useState();
  useEffect(() => {
    if(doc_init === false){
      (async () => {
        doc_init = true
        fe.messages_manage_init(event)

        // var docid = await cdb.doc_init(event);
        // set_user_id(docid)
        //set_docID(docid)
      })();
    }
  }, []); 

  //——————V2.0的新状态——————
  const [safe_hight, set_safe_hight] = useState()
  const [navbar, set_navbar] = useState(null);
  const [renderQR, set_renderQR] = useState(() => () => {})
  const [request, set_request] = useState(null);
  
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/MicroMessenger/i.test(userAgent)) {
      return
    } else{
      set_navbar({ title: 'Hi-Law劳动争议法律咨询' });
    }
  }, []);

  //——————V1.3传递全局状态——————
  // const event = new Object();
  // event.setPlaceholder = setPlaceholder
  // event.step_index = step_index
  // event.setIndex = setIndex
  // event.messages = messages
  // event.appendMsg = appendMsg
  // event.setTyping = setTyping
  // event.this_case = this_case
  // event.set_case = set_case
  // event.setInputable = setInputable
  // event.defaultQR = defaultQR
  // event.setQR = setQR
  // event.mibce = mibce
  // event.set_mibce = set_mibce
  // event.doc_id = doc_id
  // event.set_docID = set_docID
  // event.init_state = init_state
  // event.setInit = setInit
  // event.app = app
  // event.db = db
  // event.collection = collection
  //———————————————————————

  //——————V1.4传递全局状态——————
  const event = new Object();
  event.setPlaceholder = setPlaceholder
  event.step_index = step_index
  event.setIndex = setIndex
  event.mibce = mibce
  event.set_mibce = set_mibce
  event.this_case = this_case
  event.set_case = set_case
  event.setInputable = setInputable
  event.messages = messages
  event.appendMsg = appendMsg
  event.setTyping = setTyping
  event.doc_id = doc_id
  event.set_docID = set_docID
  event.init_state = init_state
  event.setInit = setInit
  event.app = app
  event.db = db
  event.collection = collection
  event.safe_hight = safe_hight
  event.set_safe_hight = set_safe_hight
  event.renderQR = renderQR
  event.set_renderQR = set_renderQR

  event.defaultQR = defaultQR
  event.setQR = setQR

  event.request = request
  event.set_request = set_request

  event.user_id = user_id
  event.set_user_id = set_user_id

  //——————————————————————————

  // useEffect(() => {
  //   console.log('user_id:',event.docid)
  //   be_vf.launch(event,event.user_id)
  // }, []);

  //——————V2.0传递全局状态——————
  // const event = new Object();
  // event.messages = messages
  // event.appendMsg = appendMsg
  // event.setTyping = setTyping
  // event.doc_id = doc_id
  // event.set_docID = set_docID
  // event.init_state = init_state
  // event.setInit = setInit
  // event.app = app
  // event.db = db
  // event.collection = collection
  //———————————————————————
  
  //用户输入回调
  const handleSend = (type,val)=>{
    event.val = val
    //console.log('event:',event)
    if (type === 'text' && val.trim() && inputable === true) {
      //将用户输入添加到会话列表
      appendMsg({
        type: 'text',
        content: { text: val },
        position: 'right',
      });
      setInputable(false)
      setTyping(true);
      //清除这一轮快捷回复
      let length = event.defaultQR.length
      event.defaultQR.splice(-length, length)
      be_vf.text(event,event.user_id,val)
      //be.main(event)
    }else if(type === 'text' && val.trim() && inputable === false){
      appendMsg({
        type: 'disable_reply',
      });
      setTyping(true);
    }
  }

  //快捷短语回调
  function handleQRClick(item){
    if(inputable === true){
      fe.handleQRClick(item,event)
    }else if(inputable === false && item.name === '流程指南'){
      fe.handleQRClick(item,event)
      setTyping(true);
    }else if(inputable === false && item.name !== '流程指南'){
      appendMsg({
        type: 'disable_reply',
      });
      setTyping(true);
    }
  }  

  useEffect(() => {
    setTimeout(() => {
      if (msgRef && msgRef.current) {
        msgRef.current.scrollToEnd({ animated: false, force: true });
      }
  }, 100);
  }, [messages,defaultQR]);

  //返回React元素
  return (
    <Chat 
      inputable={inputable}
      safetyAreaHeight={safe_hight} 
      //renderQuickReplies = {renderQR}
      navbar={navbar}
      messages={messages}
      messagesRef={msgRef}
      renderMessageContent={renderMessageContent}
      quickReplies={defaultQR}
      onQuickReplyClick={handleQRClick}
      onSend={handleSend}
      placeholder={placeholder}
      style={{ width: window.innerWidth, height: window.innerHeight }}
    />
  );
  

  //构造消息气泡
  function renderMessageContent(msg) {

    //——————V1.3传递部分全局状态——————
    // const event = new Object();
    // event.user_requ = user_requ
    // event.set_user_requ = set_user_requ
    // event.appendMsg = appendMsg
    // event.setIndex = setIndex
    // event.defaultQR = defaultQR
    // event.setQR = setQR
    // event.step_index = step_index
    // event.setTyping = setTyping
    //————————————————————————————

    //——————V2.0传递全局状态——————
    // const event = new Object();
    // event.messages = messages
    // event.appendMsg = appendMsg
    // event.setTyping = setTyping
    // event.doc_id = doc_id
    // event.set_docID = set_docID
    // event.init_state = init_state
    // event.setInit = setInit
    // event.app = app
    // event.db = db
    // event.collection = collection
    //———————————————————————

    if(msg.type === 'text'){
      const { content } = msg;
      return (
        <>
          <Bubble >
            <div className="msg_text">
              <p>{content.text}</p>
            </div>
          </Bubble>
        </>
      )
    }else if(msg.type === 'text_with_choice'){
      const { content } = msg;
      return (
        <>
          {/* <Bubble >
            <div className="msg_text">
              <p>{content.node.question}</p>
            </div>
          </Bubble> */}
          <HiLaw_Options_Popup_2 choice={content.choice} request={content.request} event={event}></HiLaw_Options_Popup_2>
        </>
      )
    }else if(msg.type === 'law'){
      const { content } = msg;
      return <Bubble type="text">
        <div className="law_text">
          <p>{content.text}</p>
        </div>
      </Bubble>
    }else if(msg.type === 'disable_reply'){
      return (
        <div>
          <Divider></Divider>
          <Divider>AI律师正在回复您的消息，请稍等，您可以在收到回复后继续对话～</Divider>
        </div>
      );
    }
    else if(msg.type === 'divider_with_text'){
      return (
        <div>
          <Divider></Divider>
          <Divider>{msg.content}</Divider>
        </div>
      );
    }
    else if(msg.type === '备案号'){
      return (
        <a href="https://beian.miit.gov.cn/" style={{
          color: 'inherit',
          textDecoration: 'none',
        }}>
          <div>
            <Divider>备案号：京ICP备2023014994号-1</Divider>
          </div>
        </a>
      );
    }
    else if(msg.type === 'HiLaw_CheckList'){
      return(
        <HiLaw_CheckList event={event}></HiLaw_CheckList>
      )
    }else if(msg.type === 'HiLaw_Collapse_1'){
      return(
        <HiLaw_Collapse_1></HiLaw_Collapse_1>
      )
    }else if(msg.type === 'HiLaw_Collapse_2'){
      return(
        <HiLaw_Collapse_2></HiLaw_Collapse_2>
      )
    }else if(msg.type === 'HiLaw_PayCard'){
      return(
        <HiLaw_PayCard event={event}></HiLaw_PayCard>
      )
    }else if(msg.type === 'HiLaw_DatePicker'){
      return(
        <HiLaw_DatePicker event={event}></HiLaw_DatePicker>
      )
    }else if(msg.type === 'HiLaw_Green_Notice'){
      return(
        <HiLaw_Green_Notice></HiLaw_Green_Notice>
      )
    }else if(msg.type === 'HiLaw_Yellow_Notice'){
      return(
        <HiLaw_Yellow_Notice></HiLaw_Yellow_Notice>
      )
    }else if(msg.type === 'HiLaw_Red_Notice'){
      return(
        <HiLaw_Red_Notice></HiLaw_Red_Notice>
      )
    }else{
      return (
        <Card size="xl" style={{padding: '12px'}}>
          <Stepper current={msg.index} style={{fontSize: '10px'}}>
            <Step title="判断纠纷类型" desc="初步判断案件类型和相关法条"/>
            <Step title="确认事实要素" desc="根据相关法律，进一步收集必要信息"/>
            <Step title="生成法律意见" desc="根据所有法律，事实与案件信息，生成意见"/>
            <Step title="计算补偿金额"desc="帮您计算可能的经济补偿金的具体金额"/>
          </Stepper>
        </Card>
      );
    }
  }
}

//React应用
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
