import fe from './fe-utils' //前端功能模块

const guide = new Object();

guide.pay_type = (event)=>{
    event.setIndex(event.step_index + 1)
    console.log('进入计算赔偿金，index：',event.step_index)
    //——————赔偿阶段引导——————
    setTimeout(() => {
        event.appendMsg({
            type: 'divider_with_text',
            content: '接下来帮您计算经济补偿金的具体数额。'
        });
    }, 500);
    setTimeout(() => {
        let this_text = '本环节将根据《劳动合同法》等最新现行法律为您计算经济补偿金。补偿金额由三个要素决定：赔偿类型、工资基数、工作年限，下面，天秤律师将针对各要素和您逐个确认。'
        fe.aiReply('null',this_text,event,true)
    }, 1000);
    //
    let reason = event.this_case.termination_reason
    //两倍赔偿
    if(reason === 'no_contract'){
        setTimeout(() => {
            let this_text = '基于您提供的事实(未签合同)，您可以获得“双倍工资”补偿，即：补偿金额 = 您在职的月份数 * 平均月工资。'
            fe.aiReply('null',this_text,event,true)
        }, 1500);
    }
    //N+1
    else if(reason === '单位欠薪'||reason === '强制加班'||reason === '调岗/降薪'||reason === '合同问题'||reason === '合同期满'||reason === '单位裁员'){
        setTimeout(() => {
            let this_text = `基于您提供的事实，天秤律师推理认为，您遇到的劳动纠纷接触情形符合“${reason}”所述的情形，且证据足以支撑因此可以获得“N+1”赔偿。即：补偿金额 = （N+1） * 工资基数（N为您的工作年限）。`
            fe.aiReply('null',this_text,event,true)
        }, 1500);
    }
    //2N+1
    else if(reason === '绩效不合格'||reason === '工作过失'||reason === '试用期内'||reason === '没有原因'){
        setTimeout(() => {
            let this_text = `基于您提供的事实，天秤律师推理认为，您遇到的劳动纠纷接触情形符合“${reason}”所述的情形，且证据足以支撑因此可以获得“2N+1”赔偿。即：补偿金额 = （2 * N + 1）* 工资基数（N为您的工作年限）。`
            fe.aiReply('null',this_text,event,true)
        }, 1500);
    }
    //——————————————————————
    setTimeout(() => {
        let this_law = '《劳动合同法》第四十七条规定，经济补偿按劳动者在本单位工作的年限，每满一年支付一个月工资的标准向劳动者支付。六个月以上不满一年的，按一年计算；不满六个月的，向劳动者支付半个月工资的经济补偿。\n\
劳动者月工资高于用人单位所在直辖市、设区的市级人民政府公布的本地区上年度职工月平均工资三倍的，向其支付经济补偿的标准按职工月平均工资三倍的数额支付，向其支付经济补偿的年限最高不超过十二年。\n\
本条所称月工资是指劳动者在劳动合同解除或者终止前十二个月的平均工资。'
        fe.aiReply(this_law,'null',event,true)
    }, 2000);
    setTimeout(() => {
        let this_text = '您可能获得的赔偿将与您过去一年的平均收入直接相关，请您选择薪资计算的方式，并填写对应方式的薪资。您的薪资包括所有的基础工资、福利津贴、绩效奖金等收入，但不包括股票期权收入。'
        fe.aiReply('null',this_text,event,true)
    }, 2500);
    setTimeout(() => {
        event.appendMsg({
            type: 'HiLaw_PayCard',
        });
    }, 3500);
    //——————————————————————
}

guide.pay_count = (event)=>{
    setTimeout(() => {
        event.appendMsg({
            type: 'law',
            content: { text: '《劳动合同法》第四十七条规定，经济补偿按劳动者在本单位工作的年限，每满一年支付一个月工资的标准向劳动者支付。六个月以上不满一年的，按一年计算；不满六个月的，向劳动者支付半个月工资的经济补偿。'},
        });
        event.setTyping(true);
      }, 500);
      setTimeout(() => {
        event.appendMsg({
            type: 'text',
            content: { text: `根据上述法律，您的工作年限为${event.payment_months}年`},
        });
        event.setTyping(true);
      }, 1000);
      //进入下一步
      let payment = 2 * event.payment_months * event.average_monthly_salary
      setTimeout(() => {
        event.appendMsg({
            type: 'text',
            content: { text: `综上所述，您可能获得的经济补偿金为：${payment}元（2倍补偿 * 平均月薪${event.average_monthly_salary}元 * 工作年限${event.payment_months}）`},
        });
        event.appendMsg({
            type: 'HiLaw_Collapse_2',
        });
        event.appendMsg({
            type: 'divider_with_text',
            content: '经济补偿金计算结束。'
        });
      }, 1500);
}

export default guide

