import be from './be-main' //后端功能模块
import prompt from './prompt' //prompt模块
import guide from './fe-guide'//用户引导模块
import graph from './be-graph'
import cdb from './be-cbd' //云数据库模块
import be_vf from './be-vf'

const fe = new Object();

//功能：初始化消息
fe.messages_manage_init = async (event) => {
    if(event.init_state === false){
        event.setInit(event.init_state = true)
        event.appendMsg({
            type: 'text',
            content: { text: prompt.step_0.init_1},
        });
        // event.appendMsg({
        //     type: 'HiLaw_Collapse_1',
        // });
        // event.appendMsg({
        //     type: '备案号',
        // });
        event.setTyping(true);
        // event.appendMsg({
        //     type: 'text',
        //     content: { text: '请简要描述您的问题'},
        // });
        // setTimeout(() => {
        //     fe.grapg(['init'],event)
        // }, 450)
        
        //——————V2.0——————
        var docid = await cdb.doc_init(event);
        be_vf.launch(event,docid)
        event.set_user_id(docid)
        console.log('user_id:',docid)
    }
}

//节点处理函数
fe.grapg = (node_list,event) => {
    console.log('node_list:',node_list)
    //如果node list不为空，则执行第一个节点
    if(node_list[0]){
        let node = graph[node_list[0]]()
        event.appendMsg({
            type: 'text_with_popup',
            content: {
                node: node,
                node_list:node_list
            },
        });
        console.log('current_node:',node)
    }
}

//功能：AI律师回复函数
fe.aiReply = (this_law,this_text,event,typing) => {
    //如果只有消息
    if (this_law === 'null' && this_text !== 'null'){
        if(typing){
            console.log('this_law：\n',this_law,'\nthis_text：\n',this_text)
            setTimeout(() => {
                event.appendMsg({
                    type: 'text',
                    content: { text: this_text},
                });
                event.setTyping(true);
            }, 500);
        }else{
            console.log('this_law\n',this_law,'\nthis_text\n',this_text)
            setTimeout(() => {
                event.appendMsg({
                    type: 'text',
                    content: { text: this_text},
                });
                event.setPlaceholder("请输入") 
                event.setInputable(true)
            }, 500);
        }
      }
    //如果同时有法条和消息
    else if(this_law !== 'null' && this_text !== 'null'){
    console.log('this_law\n',this_law,'\nthis_text\n',this_text)
    event.setTyping(true);
    //法条
    setTimeout(() => {
        event.appendMsg({
            type: 'law',
            content: { text: this_law} ,
        });
        event.setTyping(true);
    }, 500);
    //消息  
    setTimeout(() => {
        event.appendMsg({
            type: 'text',
            content: { text: this_text },
        });
        event.setPlaceholder("请输入")  
        event.setInputable(true)
    }, 1000);
    }
    //如果只有法条
    else if(this_law !== 'null' && this_text === 'null'){
        setTimeout(() => {
            event.appendMsg({
                type: 'law',
                content: { text: this_law },
            });
            event.setPlaceholder("请输入") 
            event.setInputable(true)
        }, 500);
    }
}

//功能：LLM未按要求输出时调用的函数
fe.llmFreeStyleReply = (llmFreeStyle,appendMsg,setTyping) => {
    if (llmFreeStyle === -1||llmFreeStyle === '-1'){
    setTimeout(() => {
        appendMsg({
            type: 'text',
            content: { text: '为了更好的帮助您，请按要求重新输入，以继续流程。'},
        });
    }, 500);
    }else{
    setTimeout(() => {
        appendMsg({
            type: 'text',
            content: { text: llmFreeStyle},
        });
        setTyping(true);
    }, 500);
    //消息  
    setTimeout(() => {
        appendMsg({
            type: 'text',
            content: { text: '为了更好的帮助您，请按要求重新输入，以继续流程。'},
        });
    }, 1000);
    }    
}

//功能：增加快捷回复
//备注：连着用2次会有bug，第一次的内容会无法加入
fe.addQR = async ([defaultQR, setQR],names,request) => {
    let quickReplies = []
    defaultQR.forEach((element) => {
        quickReplies.push(element)
    })
    names.forEach((name,index) => {
        quickReplies.push({
            name,
            isHighlight: true,
            request:request[index]
        });
    });
    setTimeout(() => {
        setQR(quickReplies)
    }, 500);
}  

//功能：快捷回复回调
fe.handleQRClick = (item,event) => {
    console.log('item_fe:', item)
    if(item.name === '流程指南'){
        console.log('step_index in fe:',event.step_index)
        if(event.step_index < 4){
          var index = 0
        }else if(event.step_index < 5){
          var index = 1
        }else if(event.step_index < 6){
          var index = 2
        }else if(event.step_index < 7){
            var index = 3
          }
        event.appendMsg({
          type: 'card',
          index: index,
        });
    }else{
        event.val = item.name
        //将用户输入添加到消息历史
        event.appendMsg({
            type: 'text',
            content: { text: item.name},
            position: 'right',
        });
        event.setInputable(false)
        event.setTyping(true);
        let length = event.defaultQR.length
        event.defaultQR.splice(-length, length)
        be_vf.buttons(event,event.user_id,item.request)
    }
}

//功能：格式化会话片段
fe.format_messages = (val,messages,start,end) => {
    //截取会话
    let this_session = messages.slice(start,end)//用法：https://www.runoob.com/jsref/jsref-slice-array.html
    let formatted_messages_session = []
    function format_session(val){
    let mesg = new Object()
    if(val.content !== undefined){
        if(val.position === 'left'){
            mesg.role="assistant";
            mesg.content=val.content.text;
        }else{
            mesg.role="user";
            mesg.content=val.content.text;
        }
        console.log('mesg:',mesg)
        formatted_messages_session.push(mesg)
    }
    }
    this_session.forEach(format_session)//对选定片段里的每个对话进行格式化
    //将当前用户输入格式化
    let mesg = new Object()
    mesg.role='user';
    mesg.content=val;
    formatted_messages_session.push(mesg)
    //检验格式化messages list
    console.log('formatted_messages_session:',formatted_messages_session)
    return formatted_messages_session
}

//comming_soon
fe.comming_soon = (appendMsg,setPlaceholder,setInputable) => {
    setTimeout(() => {
        appendMsg({
            type: 'text',
            content: { text: '后续功能开发中，敬请期待～ 您可以刷新重试其他情况'},
        });
        setPlaceholder("请输入") 
        setInputable(true)
    }, 600);
}

fe.notice = (event,type) => {
    if(type === 'green'){
        event.appendMsg({
            type: 'HiLaw_Green_Notice'
        });
        event.setTyping(true);
        event.setPlaceholder("AI律师回复中，请稍候")  
    }else if(type === 'yellow'){
        event.appendMsg({
            type: 'HiLaw_Yellow_Notice'
        });
        event.setTyping(true);
        event.setPlaceholder("AI律师回复中，请稍候")  
    }else if(type === 'red'){
        event.appendMsg({
            type: 'HiLaw_Red_Notice'
        });
        event.setPlaceholder("请输入") 
        event.setInputable(true)
    }
}

export default fe


