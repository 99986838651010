//llm模块
import cloudbase from "@cloudbase/js-sdk";

const llm = new Object();

//云函数名：llm_format_input；功能：将用户输入格式化为选项。
llm.format = async function (this_input,this_options,this_context,callback){
    const app = cloudbase.init({
      env: process.env.REACT_APP_CLOUDBASE_ENV
    });
    app
      .callFunction({
        name:'llm_format_input',
        data:{
          'this_context': this_context,
          'this_input': this_input,
          'this_options': this_options
        }
      })
      .then((res) => {
        let output = res.result        
        console.log(`LLM output:${output}`);
        callback(output)
      })
      .catch(console.error);
  }

//云函数名：llm_classify_input；功能：通过LLM判断属于的情形。
llm.classify = async function (doc_id,proposing_party,messages){
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  app
    .callFunction({
      name:'llm_classify_input',
      data:{
          'proposing_party': proposing_party,
          'messages': messages,
          'doc_id':doc_id
      }
    })
    .catch(console.error);
}

//云函数名：llm_function_call；功能：通过function call获取JSON。
llm.confirm_1 = async function (doc_id,messages,termination_reason){//callback如果有的话:调用llm.classify
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  app
    .callFunction({
      name:'llm_function_call',
      data:{
        'messages': messages,
        'termination_reason': termination_reason,
        'doc_id':doc_id
      }
    })
    .then((res)=>{
      console.log('云函数调用完成: ',res)
    })
    .catch(console.error);
}

//云函数名：llm_confirm_evidence；功能：确认证据。
llm.confirm_2 = async function (doc_id,this_prompt,this_law,this_context,this_input){
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  app
    .callFunction({
      name:'cloud_function_agency',
      data:{
        'this_function':'llm_confirm_evidence',
        'doc_id':doc_id,
        'this_prompt': this_prompt,
        'this_law': this_law,
        'this_context': this_context,        
        'this_input': this_input
      }
    })
    .then((res) => {
      console.log('云函数调用完成：',res)            
    })
    .catch(console.error);
}

//云函数名：llm_judge_compensation；功能：判断能否获得补偿。
llm.judge = async function (doc_id,llm_reply){
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  app
    .callFunction({
      name:'cloud_function_agency',
      data:{
        'this_function':'llm_judge_compensation',
        'doc_id':doc_id,
        'llm_reply':llm_reply
      }
    })
    .then((res) => {
      console.log('云函数调用完成：',res)            
    })
    .catch(console.error);
}

//云函数名：fc_classify_input
//——————参数定义——————
//- message：消息列表
//- doc_id：返回结果存储的文档id
llm.fc_classify_input = async function (doc_id,messages){
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  app
    .callFunction({
      name:'fc_classify_input',
      data:{
        'messages': messages,
        'doc_id':doc_id
      }
    })
    .catch(console.error);
}

export default llm
