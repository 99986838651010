import cloudbase from "@cloudbase/js-sdk";
import fe from './fe-utils' //前端功能模块

const be_vf = new Object();

//启动对话
be_vf.launch = async (event,userID) => {
    const app = cloudbase.init({
      env: process.env.REACT_APP_CLOUDBASE_ENV
    });
    app
      .callFunction({
        name:'vf_launch',
        data:{
          'userID': userID
        }
      })
      .then((res) => {
        console.log('launch success:',res);
        //初始化变量
        let QR = false
        let buttons = false

        let this_choice = []
        let this_request = []
        //遍历结果
        res.result.forEach(element => {
          if(element.type == 'text'&& element.payload.message !== 'Unable to find relevant answer.'){
              event.appendMsg({
                  type: 'text',
                  content: { text: element.payload.message},
              });
          }else if(element.type == 'user_input_supported'){
            QR = true
          }
          else if(element.type == 'choice'){
            buttons = true
            element.payload.buttons.forEach(element => {
              this_choice.push(element.name)
              this_request.push(element.request)
            })
            console.log('this_choice:',this_choice)
          }
        });
        if(QR == false && buttons == false){
          event.set_safe_hight(null)
          event.setInputable(true)
        }else if(QR){
          event.set_safe_hight(null)
          event.setInputable(true)
          fe.addQR([event.defaultQR, event.setQR],this_choice,this_request)
        }else if(buttons){
          event.appendMsg({
            type: 'text_with_choice',
            content: {
              choice: this_choice,
              request: this_request
            },
          });
        }
      })
      .catch(console.error);
  }

  be_vf.buttons =  async (event,userID,action) => {
    const app = cloudbase.init({
      env: process.env.REACT_APP_CLOUDBASE_ENV
    });
    app
      .callFunction({
        name:'vf_buttons',
        data:{
          'userID': userID,
          'action': action
        }
      })
      .then((res) => {
        console.log('buttons success:',res);
        let QR = false
        let buttons = false

        let this_choice = []
        let this_request = []
        res.result.forEach(element => {
          if(element.type == 'text'&& element.payload.message !== 'Unable to find relevant answer.'){
              event.appendMsg({
                  type: 'text',
                  content: { text: element.payload.message},
              });
          }else if(element.type == 'user_input_supported'){
            QR = true
          }
          else if(element.type == 'choice'){
            buttons = true
            element.payload.buttons.forEach(element => {
              this_choice.push(element.name)
              this_request.push(element.request)
            })
            console.log('this_choice:',this_choice)
          }
        });
        if(QR == false && buttons == false){
          event.set_safe_hight(null)
          event.setInputable(true)
        }else if(QR && buttons==false){
          be_vf.temp(event,userID)
        }else if(QR && buttons){
          event.set_safe_hight(null)
          event.setInputable(true)
          fe.addQR([event.defaultQR, event.setQR],this_choice,this_request)
        }else if(buttons){
          event.appendMsg({
            type: 'text_with_choice',
            content: {
              choice: this_choice,
              request: this_request
            },
          });
        }
      })
      .catch(console.error);
  }

  be_vf.text = async (event,userID,text) => {
    const app = cloudbase.init({
      env: process.env.REACT_APP_CLOUDBASE_ENV
    });
    app
      .callFunction({
        name:'vf_text',
        data:{
          'userID': userID,
          'text':text
        }
      })
      .then((res) => {
        console.log('text success:',res);
        let QR = false
        let buttons = false

        let this_choice = []
        let this_request = []
        res.result.forEach(element => {
          if(element.type == 'text'&& element.payload.message !== 'Unable to find relevant answer.'){
              event.appendMsg({
                  type: 'text',
                  content: { text: element.payload.message},
              });
          }else if(element.type == 'user_input_supported'){
            QR = true
          }
          else if(element.type == 'choice'){
            buttons = true
            element.payload.buttons.forEach(element => {
              this_choice.push(element.name)
              this_request.push(element.request)
            })
            console.log('this_choice:',this_choice)
          }
        });
        if(QR == false && buttons == false){
          event.set_safe_hight(null)
          event.setInputable(true)
        }else if(QR && buttons==false){
          be_vf.temp(event,userID)
        }else if(QR && buttons){
          event.set_safe_hight(null)
          event.setInputable(true)
          fe.addQR([event.defaultQR, event.setQR],this_choice,this_request)
        }else if(QR==false && buttons){
          event.appendMsg({
            type: 'text_with_choice',
            content: {
              choice: this_choice,
              request: this_request
            },
          });
        }
      })
      .catch(console.error);
  }

  be_vf.temp = async (event,userID) => {
    const app = cloudbase.init({
      env: process.env.REACT_APP_CLOUDBASE_ENV
    });
    app
      .callFunction({
        name:'vf_text',
        data:{
          'userID': userID,
          'text':'next step'
        }
      })
      .then((res) => {
        console.log('text success:',res);
        let this_choice = []
        let this_request = []
        res.result.forEach(element => {
          if(element.type == 'text'&& element.payload.message !== 'Unable to find relevant answer.'){
              event.appendMsg({
                  type: 'text',
                  content: { text: element.payload.message},
              });
          }else if(element.type == 'choice'){
            element.payload.buttons.forEach(element => {
              this_choice.push(element.name)
              this_request.push(element.request)
            })
            console.log('this_choice:',this_choice)
          }
        });
        event.set_safe_hight(null)
        event.setInputable(true)
        fe.addQR([event.defaultQR, event.setQR],this_choice,this_request)
      })
      .catch(console.error);
  }

export default be_vf



//返回的数据格式示例
let res_date = [
  {
    "type":"path",
    "payload":{
      "path":"choice:1"
    }
  },
  {
    "type":"text",
    "payload":{
      "slate":{
        "id":"ib5680dut","content":[
          {
            "children":[
              {"text":"单位提出辞退的理由是？"}
            ]
          }
        ],
        "messageDelayMilliseconds":1000
      },
      "message":"单位提出辞退的理由是？",
      "delay":1000
    }
  },
  {
    "type":"choice",
    "payload":{
      "buttons":
        [
          {
            "name":"绩效问题",
            "request":{
              "type":"path-ym33m3odi",
              "payload":{
                "label":"绩效问题",
                "actions":[]
              }
            }
          },
          {
            "name":"合同期满",
            "request":{
              "type":"path-8c38f3ooy",
              "payload":{
                "label":"合同期满",
                "actions":[]
              }
            }
          }
        ]
    }
  }
]