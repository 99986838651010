import React, { useRef, useState, useEffect } from 'react'
import { Checkbox, List ,Form, Collapse ,Button, Input, Selector, DatePicker, Popup } from 'antd-mobile'
import { Card, CardTitle, CardText, CardMedia, Divider, Bubble, Icon} from 'chatui-core';
import 'chatui-core/es/styles/index.less';
import 'chatui-core/dist/index.css';
import './chatui-theme.css';
import './index.css';
import prompt from './prompt' //prompt模块
import fe from './fe-utils' //前端功能模块
import dayjs from 'dayjs'
import { CloseCircleFill } from 'antd-mobile-icons'
import guide from './fe-guide'//用户引导模块
import be_vf from './be-vf'


//——————HiLaw_CheckList开始——————
export const HiLaw_CheckList = props => {
  let check_content = []
  let check_index = [false,false,false]
  const [check_index_async,set_check_index_async] = useState([false,false,false])
  //——————备注——————
  //一个有瑕疵但能用的方法
  //当check_index只有1项为true时，Button的onClick无法拿到正确的值
  //但完全用useState替换会有异步问题
  //所以这里用了变量+useState共同实现
  //之后有机会希望优化
  //———————————————
  let item = ['帮我判断是否能获得补偿','帮我计算补偿金额','给我提供维权策略']
  const [button_content,set_button_content] = useState('提交')
  const [button_state,set_button_state] = useState(true)
  const [input_state,set_input_state] = useState(false)
  const ListItemWithCheckbox = props => {
    const checkboxRef = useRef(null);
    return (
      <List.Item
        prefix={
          <div onClick={e => e.stopPropagation()}>
            <Checkbox value={props.item} ref={checkboxRef} />
          </div>
        }
        onClick={(e) => {
          if (checkboxRef.current) {
            checkboxRef.current.toggle();
          }
        }}
        arrow={false}
        disabled={input_state} 
      >
        {props.item}
      </List.Item>
    )
  }

  return (
    <Card size="xl" style={{padding: '8px'}} >
       <Form
        footer={
          <Button block type='submit' color='primary' size='samll' disabled={button_state} onClick={()=>{
            set_button_state(true)
            set_input_state(true)
            set_button_content('已提交')
            props.event.set_user_requ(check_index)
            console.log('check_index when button:',check_index_async)
            if(check_index_async[0] === true||check_index_async[1] === true||check_index_async[2] === true){
              setTimeout(() => {
                props.event.appendMsg({
                    type: 'text',
                    content: { text: prompt.step_1.guidance_1},
                });
                props.event.setIndex(props.event.step_index + 1.1)
              }, 600);
              fe.addQR([props.event.defaultQR, props.event.setQR],['离职前后的问题','在职期间的问题(开发中，待上线)'])
            }
          }}>
            {button_content}
          </Button>
        }
      >
        <Form.Header>请选择您的需求（可多选）</Form.Header>
        <Checkbox.Group disabled={input_state} onChange={(value)=>{
          check_content = value
          console.log('check_content:',check_content)
          check_index = [false,false,false]
          check_content.forEach(currentValue=>{
            if(currentValue === item[0]){
                check_index[0] = true
            }else if(currentValue === item[1]){
                check_index[1] = true
            }else if(currentValue === item[2]){
                check_index[2] = true
            }
          })
          set_check_index_async(check_index)
          console.log('check_index:',check_index)
          if(check_index[0] === true||check_index[1] === true||check_index[2] === true){
            set_button_state(false)
          }else{
            set_button_state(true)
          }
        }}>
          <List style={{'--font-size': '14px'}}> 
              <ListItemWithCheckbox key={0} item={item[0]} />
              <ListItemWithCheckbox key={1} item={item[1]} />
              <ListItemWithCheckbox key={2} item={item[2]} />
          </List>
        </Checkbox.Group>
      </Form>
    </Card>
  )
}
//——————HiLaw_CheckList结束——————

//——————HiLaw_Collapse开始——————
export const HiLaw_Collapse_1 = function(){
  //padding: '8px'
  return(
    <Card size="xl" style={{}} >
      <Collapse style={{}} arrow={<Icon type="chevron-down" style={{
        width: '16px',
        height: '16px',
        fill: 'rgba(0, 0, 0, 0.2)', 
      }}/>}>
        <Collapse.Panel key='1' title='阅读更多：关于天秤律师' style={{'fontSize': '14px'}}>
          <CardTitle title='使用指引'></CardTitle>
          <CardMedia image="/guide_img_s.jpeg" aspectRatio='wide'/>
          <CardText>为保证法律意见的准确性，天秤律师会首先向您询问一些问题，您可以点击任意选项或在输入框中手动输入。</CardText>
          <Divider></Divider>
        <Divider>隐私提示：天秤律师遵守《数据安全法》与《生成式人工智能管理办法》，保障您咨询过程中的全部隐私。</Divider>
        </Collapse.Panel>
      </Collapse>
    </Card>
  )
}
//——————HiLaw_Collapse结束——————

//——————HiLaw_Collapse_2开始——————
export const HiLaw_Collapse_2 = function(){
  let this_text = '加班工资的具体计算就是以月工资标准折算出日工资标准或小时工资标准，再依法按照劳动者本人日工资标准或小时工资标准的150%、200%、300%支付劳动者工资。2008年1月3日《劳动和社会保障部关于职工全年月平均工作时间和工资折算问题的通知》（劳社部发〔2008〕3号）规定，按照《劳动法》第五十一条的规定，法定节假日用人单位应当依法支付工资，即折算日工资、小时工资时不剔除国家规定的11天法定节假日。据此，日工资、小时工资的折算为：\n\
  日工资=月工资收入÷月计薪天数；\n\
  小时工资=月工资收入÷（月计薪天数×8小时）；\n\
  月计薪天数=（365天-104天）÷12月=21.75天。\n\
  用人单位在实践中需要特别注意准确计算劳动者的工资数额，首先如果劳动者和用人单位在劳动合同中有明确约定的工资数额，且该工资数额不低于当地最低工资标准和集体合同约定的工资标准的，则以劳动合同中约定的工资数额计算加班工资。如果劳动关系双方当事人没有在劳动合同中明确约定工资数额，则应当以用人单位实际支付给劳动者的工资数额作为加班工资的计算基数。在这里，用人单位发放给劳动者的奖金、津贴、补贴等均属于实际发放的工资的组成部分，用人单位不能仅仅以工资项目中的“岗位工资”、“职务工资”或“技能工资”中任何一项或几项作为计算加班工资的基数。计件工资制度下加班工资计算基数则是法定工作时间内的计件单价。'
  //padding: '8px'
  return(
    <Card size="xl" style={{}} >
      <Collapse style={{}}>
        <Collapse.Panel key='1' title='阅读更多：关于工资' style={{'fontSize': '14px'}}>
          <CardText>{this_text}</CardText>
        </Collapse.Panel>
      </Collapse>
    </Card>
  )
}
//——————HiLaw_Collapse_2结束——————


let average_monthly_salary = 0
//——————HiLaw_PayCard开始——————
export const HiLaw_PayCard = props => {
  const [input_state,set_input_state] = useState(false)
  const [button_state,set_button_state] = useState(true)
  const onFinish = (values) => {
    console.log('values:',values)
    set_input_state(true)
    set_button_state(true)
    if(values.salaryform === '1' || values.salaryform[0] === '1'){
      average_monthly_salary = Math.ceil(values.salary/12)
      console.log('计算后的月薪：',average_monthly_salary)
    }else if(values.salaryform === '2' || values.salaryform[0] === '2'){
      average_monthly_salary = values.salary
      console.log('月薪：',average_monthly_salary)
    }
    
    props.event.setTyping(true);
    setTimeout(() => {
      props.event.appendMsg({
          type: 'text',
          content: { text: `您的平均月薪为：${average_monthly_salary}`},
      });
      props.event.setTyping(true);
    }, 500);
    setTimeout(() => {
      props.event.appendMsg({
          type: 'text',
          content: { text: '接下来为您计算在职时间对应的经济补偿金倍数。请问您在本单位开始和（希望）结束工作的时间是？（建议精确到日期）'},
      });
      props.event.setTyping(true);
    }, 1000);
    setTimeout(() => {
      props.event.appendMsg({
          type: 'HiLaw_DatePicker'
      });
    }, 1500);
  }

  return (
    <Card size="xl" style={{padding: '2px'}} >
      <Form
        onFinish={onFinish}
        initialValues={{
          salaryform:'1',
        }}
        footer={
          <Button block type='submit' color='primary' size='middle' disabled={button_state}>
            提交
          </Button>
        }
        mode='card'
      >
        <Form.Item name='salaryform' label='请选择薪资计算形式' disabled={input_state} rules={[{ required: true, message: '不能为空' }]}>
          <Selector
            columns={2}
            options={[
              {
                label: '年薪',
                description: '过去一年全部所得',
                value: '1',
              },
              {
                label: '月薪',
                description: '过去一年平均月所得',
                value: '2',
              },
            ]}
          />
        </Form.Item>
        <Form.Item 
          name ='salary'
          label='薪资金额'
          disabled={input_state}
          rules={[{ required: true, message: '不能为空' }]}
          extra={
            <div style={{'marginTop':'28px'}}>
              <a>元</a>
            </div>
          }
        >
          <Input placeholder='人民币元' type='number' min='0' onChange={value=>{
            if(value >= 12){
              set_button_state(false)
            }else if(value < 12){
              set_button_state(true)
            }
          }}/>
        </Form.Item>
      </Form>
    </Card>
  )
}
//——————HiLaw_PayCard结束——————


//——————HiLaw_DatePicker开始——————
export const HiLaw_DatePicker = props => {
  const [datePicker_state,set_datePicker_state] = useState([true,true,true])
  const [input_state,set_input_state] = useState(false)
  const [start_time, set_start_time] = useState()
  const event = new Object();
  event.datePicker_state = datePicker_state
  event.set_datePicker_state = set_datePicker_state
  event.start_time = start_time
  event.set_start_time = set_start_time
  event.input_state = input_state
  event.set_input_state = set_input_state
  const [form] = Form.useForm()
  const onSubmit = () => {
    set_datePicker_state([true,true,true])
    set_input_state(true)
    const values = form.getFieldsValue()
    //计算日期之间差的月份数
    function count_months(date1, date2){
      var year1 = date1.getFullYear();
      var year2 = date2.getFullYear();
      var month1 = date1.getMonth();
      var month2 = date2.getMonth();
      if(month1===0){
        console.log('count_months -> date1:',date1)
        month1++;
        month2++;
      }
      var numberOfMonths = (year2 - year1) * 12 + (month2 - month1);
      return numberOfMonths;
    }
    let counted_months = count_months(values.duration_start,values.duration_end)
    console.log('counted_months:',counted_months); 
    let payment_months = 0
    //如果小于6个月
    if(counted_months >= 0 && counted_months < 6){
      payment_months = 0.5
    }
    //如果大于6个月不满1年
    else if(counted_months >= 6 && counted_months < 12){
      payment_months = 1
    }
    //如果大于等于1年
    else if(counted_months >= 12){
      //声明变量
      let after_dot = undefined
      let number_after_dot = undefined
      let number_before_dot = undefined
      //将月份数除以12
      let division_res = counted_months/12
      //如果不能被整除
      if(division_res.toString().match(/\.(\d{1})/)){
        after_dot = division_res.toString().match(/\.(\d{1})/);
        number_after_dot = Number(after_dot[1])
        console.log('after_dot acquired：',after_dot[1],'\nnumber_after_dot:',number_after_dot)
        //获取小数点前的数字,并转化为数字
        let before_dot = division_res.toString().match(/(\d+)\./);
        number_before_dot = Number(before_dot[1])
        console.log('before_dot:',before_dot[1],'\nnumber_before_dot:',number_before_dot)
        //
        if(number_after_dot){
          if(number_after_dot > 0 && number_after_dot<5){
            payment_months =  number_before_dot + 0.5
          }else if(number_after_dot >= 5){
            payment_months = number_before_dot + 1
          }
        }else{
          payment_months = number_before_dot
        }
        console.log('payment_months:',payment_months)
      }
      //如果能被整除
      else{
        console.log('after_dot not acquired')
        payment_months = division_res
        console.log('payment_months:',payment_months)
      }
    }
    props.event.payment_months = payment_months
    props.event.average_monthly_salary = average_monthly_salary
    guide.pay_count(props.event)
  }

  return (
    <Card size="xl" style={{padding: '2px'}} >
      <Form
        form={form}
        onFieldsChange = {(e) => {
          console.log('e[0].name[0]:',e[0].name[0])
          if(e[0].name[0] === 'duration_start'){
            set_datePicker_state(datePicker_state.map((currentValue,index,arr)=>{
              console.log('currentIndex:',index,'currentValue:',currentValue)
              if(index === 0){
                return false
              }else{
                return currentValue
              }
            }))
          }else if(e[0].name[0] === 'duration_end'){
            set_datePicker_state(datePicker_state.map((currentValue,index,arr)=>{
              console.log('currentIndex:',index,'currentValue:',currentValue)
              if(index === 1){
                return false
              }else{
                return currentValue
              }}))
          }
          if(datePicker_state[0] === false && datePicker_state[1] === false){
            set_datePicker_state(datePicker_state.map((currentValue,index)=>{
              if(index === 2){
                return false
              }else{
                return currentValue
              }
            }))
          }
        }}
        footer={
          <Button block color='primary' onClick={onSubmit} size='middle' disabled={datePicker_state[2]}>
            提交
          </Button>
        }
      >
        <StartDatePicker event={event}/>
        <EndDatePicker event={event}/>
      </Form>
    </Card>
  )
}

const StartDatePicker = props => {
  const now = new Date()
  const [this_value, set_this_value] = useState()
  return (
    <Form.Item 
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.duration_start !== curValues.duration_start
      }
    >
      {({ getFieldValue, setFieldsValue }) => (
        <Form.Item 
          disabled={props.event.input_state}
          rules={[{ required: true, message: '不能为空' }]}
          name='duration_start'
          label='开始工作的时间'
          trigger='onConfirm'
          onClick={(e, datePickerRef) => {
            datePickerRef.current?.open() 
          }}
          arrow={
            getFieldValue('duration_start') ? (
              <CloseCircleFill
                style={{
                  color: 'var(--adm-color-light)',
                  fontSize: 16,
                }}
                onClick={e => {
                  e.stopPropagation()
                  setFieldsValue({ duration_start: null })
                  props.event.set_start_time(undefined)
                  props.event.set_datePicker_state(props.event.datePicker_state.map((currentValue,index)=>{
                    console.log('currentIndex:',index,'currentValue:',currentValue)
                    if(index === 0 || index === 2){
                      return true
                    }else{
                      return currentValue
                    }
                  }))
                }}
              />
            ) : (
              true
            )
          }
        >
          <DatePicker 
            title='请输入在本单位开始工作的时间'
            max={now}
            onConfirm={v => {
              props.event.set_start_time(v)
            }}
          >
            {
              value =>{
                // set_this_value(value)
                return value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期';
              }
            }
          </DatePicker>
        </Form.Item>
      )}
    </Form.Item>
  )
}

const EndDatePicker = props => {
  const [pickerVisible, setPickerVisible] = useState(false)

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) =>
        prevValues.duration_end !== curValues.duration_end
      }
    >
      {({ getFieldValue, setFieldsValue }) => (
        <Form.Item 
          disabled={props.event.input_state}
          rules={[{ required: true, message: '不能为空' }]}
          name='duration_end'
          label='（预计）结束工作的时间'
          trigger='onConfirm'
          arrow={
            getFieldValue('duration_end') ? (
              <CloseCircleFill
                style={{
                  color: 'var(--adm-color-light)',
                  fontSize: 16,
                }}
                onClick={e => {
                  e.stopPropagation()
                  setFieldsValue({ duration_end: null })
                  props.event.set_datePicker_state(props.event.datePicker_state.map((currentValue,index)=>{
                    console.log('currentIndex:',index,'currentValue:',currentValue)
                    if(index === 1 || index === 2){
                      return true
                    }else{
                      return currentValue
                    }}))
                }}
              />
            ) : (
              true
            )
          }
          onClick={() => {
            setPickerVisible(true)
          }}
        >
          <DatePicker
            title='请输入（预计）结束在本单位工作的时间'
            visible={pickerVisible}
            onClose={() => {
              setPickerVisible(false)
            }}
            min={props.event.start_time}
          >
            {value =>
              value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'
            }
          </DatePicker>
        </Form.Item>
      )}
    </Form.Item>
  )
}
//——————HiLaw_DatePicker结束——————


//——————HiLaw_Yellow_Notice开始——————
export const HiLaw_Yellow_Notice = props => {
  return (
    <Bubble style={{
      padding:' 10px',
      backgroundColor:' #fef3e6',// light orange
      border: '1px solid #f7c797',// darker orange
      borderRadius: '10px',
      display: 'inline-block',
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.85)'
    }}>
      <div style={{
        display: 'flex',/* 使用 Flexbox 布局 */
        alignItems: 'center', /* 垂直居中对齐图标和文字 */
      }}>
      <Icon type='warning-circle' style={{
        width: '20px',
        height: '20px',
        fill: '#d35a21', 
      }}/>
        <p style={{
          marginLeft: '8px'
        }}>当前网络状态不好，程序正在重试，请稍候。</p>
      </div>
    </Bubble>
  );
}
//——————HiLaw_Yellow_Notice结束——————

//——————HiLaw_Red_Notice开始——————
export const HiLaw_Red_Notice = props => {
  return (
    <Bubble style={{
      padding:' 10px',
      backgroundColor:' #fdecee',
      border: '1px solid #f8b9be',
      borderRadius: '10px',
      display: 'inline-block',
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.85)'
    }}>
      <div style={{
        display: 'flex',/* 使用 Flexbox 布局 */
        alignItems: 'center', /* 垂直居中对齐图标和文字 */
      }}>
      <Icon type='x-circle-fill' style={{
        width: '20px',
        height: '20px',
        fill: '#e34d59', 
      }}/>
        <p style={{
          marginLeft: '8px'
        }}>感谢您的等待，当前网络连接失败，请恢复网络后重新尝试。</p>
      </div>
    </Bubble>
  );
}
//——————HiLaw_Red_Notice结束——————

//——————HiLaw_Green_Notice开始——————
export const HiLaw_Green_Notice = props => {
  return (
    <Bubble style={{
      padding:' 10px',
      backgroundColor:' #e8f8f2',
      border: '1px solid #85dbbe',
      borderRadius: '10px',
      display: 'inline-block',
      fontSize: '10px',
      color: 'rgba(0, 0, 0, 0.85)'
    }}>
      <div style={{
        display: 'flex',/* 使用 Flexbox 布局 */
        alignItems: 'center', /* 垂直居中对齐图标和文字 */
      }}>
      <Icon type="smile" style={{
        width: '20px',
        height: '20px',
        fill: '#078d5c', 
      }}/>
        <p style={{
          marginLeft: '8px'
        }}>感谢您的等待，模型正在响应中，很快就有结果，请稍候。</p>
      </div>
    </Bubble>
  );
}
//——————HiLaw_Green_Notice结束——————

//——————HiLaw_Options_Popup_2开始——————
export const HiLaw_Options_Popup_2 = props => {
  const [item, setItem] = useState([])//选项内容
  const [request, setRequest] = useState([])//调用vf API用的request内容
  let check_content = [] //用户选择的选项内容
  let check_index = [] //用户选择的选项编号
  let safe_hight = 88
  let doc_init = [false,false]
  const [confirm_only, set_confirm_only] = useState(false)//是否只需要确认

  useEffect(() => {
    if(doc_init[0] !== true){
      (() => {
        doc_init[0] = true
        console.log('init')
        //如果只需要确认
        if(props.choice[0] === '确认' && props.choice.length === 1){ //这里的“确认”要跟VF设定一致
          set_confirm_only(true)
          set_button_state(false)
        }else{
          setItem(props.choice)
        }
        setRequest(props.request)
        setTimeout(() => {
          setVisible(true)
        }, 0);
      })();
    }
  }, []); 

  //聊天区域安全区高度
  useEffect(() => {
    item.forEach((item,index)=>{
      if(index !== 0){
        safe_hight = safe_hight + 1
      }
      safe_hight = safe_hight + 42
      console.log('safe_hight:',safe_hight)
    })
    props.event.set_safe_hight(safe_hight)
  }, [item]); 

  //组件状态
  const [value, set_value] = useState([])
  const [selected, set_selected] = useState(null)
  const [check_index_number, set_check_index_number] = useState()
  const [visible, setVisible] = useState(false)
  const [button_state,set_button_state] = useState(true)
  const [button_content,set_button_content] = useState('确认')

  //变量同步
  const [check_content_async,set_check_content_async] = useState([])
  const [check_index_async,set_check_index_async] = useState(item.map(() => {
    check_index.push(false)
  }))
  //——————备注——————
  //一个有瑕疵但能用的方法
  //当check_index只有1项为true时，Button的onClick无法拿到正确的值
  //但完全用useState替换会有异步问题
  //所以这里用了变量+useState共同实现
  //之后有机会希望优化
  //———————————————
  
  const ListItemWithCheckbox = props => {
    const checkboxRef = useRef(null);
    return (
      <List.Item
        prefix={
          <div onClick={e => e.stopPropagation()}>
            <Checkbox value={props.item} ref={checkboxRef} />
          </div>
        }
        onClick={(e) => {
          if (checkboxRef.current) {
            checkboxRef.current.toggle();
          }
        }}
        arrow={false}
      >
        {props.item}
      </List.Item>
    )
  }
  
  return (
    <Popup
      mask={false}
      visible={visible}
      destroyOnClose='true'
      bodyStyle={{
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        minHeight: '88px',
        paddingTop: '8px'
      }}
    >
      <Checkbox.Group value={value} onChange={(value)=>{
          check_content = (()=>{
            // //如果是单选
            // if(props.node.inputType === "select"){
              if(selected === null){
                //console.log('selected === null')
                set_value(value)
                set_selected(value)
                return value
              }else{
                //console.log('selected !== null')
                //console.log('selected:',selected)
                let temp_value = value.filter(value => value !== selected[0])
                set_value(temp_value)
                set_selected(temp_value)
                return temp_value
              }
            // //如果是多选
            // }else if(props.node.inputType === "multi-select"){
            //   set_value(value)
            //   return value
            // }
          })()
          console.log('check_content:',check_content)
          //根据选项内容得到序号
          set_check_content_async(value)
          item.map((item,index)=>{
            check_content.forEach((currentValue)=>{
              if(currentValue === item){
                  check_index[index] = true
                  set_check_index_number(index)
              }
            })
          })
          set_check_index_async(check_index)
          console.log('check_index:',check_index)
          console.log('check_index_number:',check_index_number)//这里是异步的
          //根据value的值渲染按钮状态
          let input
          check_index.forEach((value)=>{if(value===true){input = true}})
          if(input){
            set_button_state(false)
          }else{
            set_button_state(true)
          }
        }}>
        <List> 
            {item.map((item,index) => (
              <ListItemWithCheckbox key={index} item={item} />
            ))}
        </List>
      </Checkbox.Group>
  
      <Button color='primary' size='middle' shape='rounded' disabled={button_state}
        style={{
          margin: '20px auto',
          display: 'flex',
          justifyContent: 'center',
          width: 'calc(100% - 40px)'
        }}
        onClick={()=>{
          //状态渲染
          set_button_state(true)
          setVisible(false)
          props.event.set_safe_hight(16)
          //如果直接进行下一步
          if(confirm_only){
            setTimeout(() => {
              console.log('next step')
              be_vf.buttons(props.event,props.event.user_id,request[0])
            }, 400);
          //如果需要根据选项进行跳转
          }else{
            //输入处理
            console.log('check_content_async',check_content_async)
            props.event.appendMsg({
              type: 'text',
              content: { text: value},
              position: 'right',
            });
            props.event.setTyping(true);
            setTimeout(() => {
              console.log('request[check_index_number]:',request[check_index_number])
              be_vf.buttons(props.event,props.event.user_id,request[check_index_number])
            }, 400);
          }
        }}
      >
        {button_content}
      </Button>
      <div className='safe-hight'></div>
    </Popup>
  );
}
//——————HiLaw_Options_Popup结束——————