import cloudbase from "@cloudbase/js-sdk"

import fe from './fe-utils' //前端功能模块

const cdb = new Object();

//云数据库创建doc
cdb.doc_init = async (event) => {
  return new Promise(async (resolve, reject) => {
    //——————模块主函数———————
    const mian = async (process) => {
      // async function login(){
      //   await event.app.auth().signInAnonymously();
      //   const loginState = event.app.auth().hasLoginState()
      //   console.log('loginState:',loginState); 
      // }
      // login()
      event.app
      .auth({
        persistence: "local"
      })
      .anonymousAuthProvider()
      .signIn()
      .then(() => {
        console.log(`${process}成功`)
        const currentTime = new Date();
        event.collection
          .add({
            case_start: currentTime
          })
          .then((res) => {
            console.log('doc init res:',res)
            event.set_docID(res.id)
            resolve(res.id); // 这里解析 Promise
          })
          .catch((error) => {
            error_log(error,'doc_init')
            //错误处理方式待完善
          });
      })
    }

    //——————执行主函数：匿名登陆——————
    mian('匿名登陆')
    .catch((err) => {
      error_log(err,'匿名登陆')
      setTimeout(() => {
        console.log('匿名登陆重试')
        mian('匿名登陆重试')
        .catch((err) => {
          error_log(err,'匿名登陆重试')
        })
      }, 0);
    });
  })
}

//对话上云
cdb.doc_update = async (doc_id,messages) => {
  //——————云开发初始化——————
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  const db = app.database();
  const collection = db.collection(process.env.REACT_APP_CASE_DB); 
  //——————————————————————
  const _ = db.command;
  collection
  .doc(doc_id)
  .update({
    messages: _.set(messages)
  })
  .then((res) => {
    console.log('messages unpdate success:',res)
  })
  .catch((err) => {
    error_log(err,'doc_update')
    //错误处理方式待完善
  });
}

//classify->云数据库+function call
cdb.classify = async (doc_id,formatted_messages_session,callback_1,callback_2,callback_3) => {
  //——————云开发初始化——————
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  const db = app.database();
  const collection = db.collection(process.env.REACT_APP_CASE_DB); 
  let websocket_index = 0
  //——————模块主函数———————
  const mian = (process) => {
    console.log(`${process} success`);
    websocket_index = websocket_index + 1
    //当监听到change
    if (websocket_index == 2){
      collection
        .where({
          _id: doc_id,
        })
        .get()
        .then((res) => {
          console.log('res:',res)
          //成功返回
          if(res.data[0].temp.output.finish === false){
            callback_2(res.data[0].temp.output.content)
          }else if(res.data[0].temp.output.finish === true){
            callback_3(res.data[0].temp.output.content)
          }
        })
        .catch((error) => {
          error_log(error,'doc_get')
        });
        //关闭监听
        ref.close();
    }
  }
  //——————创建监听——————
  let ref = collection.doc(doc_id).watch({
    onChange: () => {
      mian('websocket')
    },
    onError: (error) => {
      websocket_index = 0
      error_log(error,'websocket')
      mian('websocket retry')
    }
  });
  //——————调用llm.classify——————
  callback_1(formatted_messages_session)
}

//confirm_1->云数据库+function call
cdb.confirm_1 = async (event,callback_1,callback_2,callback_3) => {
  websocket_poll(event,'confirm_1',(llm_reply)=>{
    if(llm_reply.finish === false){
      let this_reply = llm_reply.content
      callback_2(this_reply,false)
    }else if(llm_reply.finish === true){
      let this_reply = `您的情况摘要如下：\n${llm_reply.content}` 
      callback_2(this_reply,true)
      callback_3(llm_reply.content)
    }
  })
  //调用llm.confirm_1
  callback_1()
}

//confirm_2->云数据库
cdb.confirm_2 = async (event,callback_1,callback_2,callback_3) => {
  websocket_poll(event,'confirm_2',(llm_reply)=>{
    callback_2(llm_reply)
    callback_3(llm_reply)
  })
  //调用llm.confirm_2
  callback_1()
}

//judge->云数据库
cdb.judge = async (event,callback_1,callback_2,callback_3) => {
  websocket_poll(event,'judge',(llm_reply)=>{
    let regex = /(true|false)/g;
    let result = llm_reply.content.match(regex);
    console.log('cdb.judge result:',result)
    if(result[0] === 'true'){
      callback_2()
      event.setIndex(6)
    }else if(result[0] === 'false'){
      callback_3('经过判断，您的情况可能无法获得经济补偿金，我们会在后续版本提供更多帮助您维权的功能，敬请期待。')
    }else{
      console.log("result[0] === else")
      //——————待完善——————
      //callback_2('result[0] === else')
    }
  })
  //调用llm.judge
  callback_1()
}
 
const websocket_poll = (event,value,callback) => {
  //通知状态初始化
  let yellow_notice = false
  let red_notice = false
  //轮询状态初始化
  let continue_poll = true
  //后续步骤状态初始化
  let callback_done = false
  //——————文档内容请求函数——————
  const doc_get = () => {
    event.collection
        .where({
          _id: event.doc_id,
        })
        .get()
        .then((res) => {
          console.log('res:',res)
          if(res.data[0][value]){
            //——结束请求——
            clearTimeout(timeout_websocket);
            clearTimeout(timeout_poll_yellow); 
            clearTimeout(timeout_poll_red); 
            continue_poll = false
            //———————————
            let llm_reply = res.data[0][value]
            if(callback_done === false){
              callback_done = true
              callback(llm_reply)
            }else{
              console.log('not yet')
            }
          }
        })
        .catch((error) => {
          error_log(error,'doc_get')
        });
  }
  //——————数据库监听函数———————
  let websocket_index = 0 //websocket onchange初始化
  const websocket_mian = (ref) => {
    console.log('websocket success');
    websocket_index = websocket_index + 1
    //当监听到change
    if (websocket_index >= 2){
      doc_get()
      ref.close();
    }
  }
  //——————轮询函数——————
  const poll_mian = () => {
    doc_get()
    //继续轮询
    if (continue_poll) {
      console.log('继续轮询')
      setTimeout(poll_mian, 1500);//——————> 每次轮询的间隔时间 <——————
    }
  }
  //——————创建监听——————
  let ref = event.collection.doc(event.doc_id).watch({
    onChange: () => {
      websocket_mian(ref)
    },
    onError: (error) => {
      if(yellow_notice === false){
        yellow_notice = true
        fe.notice(event,'yellow')
      }
      error_log(error,'websocket')
    }
  });
  //——————开始计时——————
  let timeout_websocket = setTimeout(() => {
    ref.close(); //关闭监听
    console.log('结束监听，开始轮询')
    poll_mian(); // 执行轮询
    fe.notice(event,'green')
  }, 15000); //——————> websocket持续的时间 <——————
  let timeout_poll_yellow = setTimeout(() => {
    if(yellow_notice === false){
      yellow_notice = true
      fe.notice(event,'yellow')
    }
  }, 30000); //——————> 出现黄色通知的时间 <——————
  let timeout_poll_red = setTimeout(() => {
    continue_poll = false
    if(red_notice === false){
      red_notice = true
      fe.notice(event,'red')
    }
    console.log('请求超时，轮询结束')
    error_log('timeout','poll')
  }, 45000); //——————> 轮询持续的时间 <——————
}

//错误日志上传
//上传内容：
//1. error对象
//2. 当前时间
const error_log = async (error,type) => {
  console.log(`${type} error log unloading:`,error)
  //——————云开发初始化——————
  const app = cloudbase.init({
    env: process.env.REACT_APP_CLOUDBASE_ENV
  });
  const db = app.database();
  const collection = db.collection(process.env.REACT_APP_ERROR_LOG_DB); 
  //——————————————————————
  const currentTime = new Date();
  collection
    .add({
      error: error,
      type: type,
      currentTime: currentTime
    })
    .then((res) => {
      console.log(`${type} error log unload:`,res)
    })
    .catch((e) => {
      console.log(`${type} error log unload fail:`,e)
    });
}


export default cdb