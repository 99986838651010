
const prompt = new Object();

//初始化
prompt.step_0 = {
    init_1: '欢迎来到Hi-Law劳动法助手，目前提供的服务包括：\n\
    - 帮您判断：是否能获得经济补偿金\n\
    - 帮您计算：经济补偿金的法定数额\n\
    - 为您提供：具体维权策略、生成劳动仲裁书申请（包含事实链梳理、证据链确认）\n\
    - 覆盖的场景：加班费、欠薪、裁员、离职、未签合同、试用期等16个常见的劳动争议细分场景',
    //init_2: '请您简述希望咨询的问题（主要的纠纷情况）'
}

//判断在职场景还是离职场景
prompt.step_1 = {
    guidance_1: '请问您想咨询的劳动场景是以下哪一类？\n\
 -> 离职或即将离职时遇到的问题（如强迫辞职，拒绝支付赔偿金等）\n\
 -> 在职时的问题（如加班、工伤等）'
}

//判断是否有合同
prompt.step_1dot2 = {
    guidance_1: '感谢您的回答，请问您是否签了劳动合同?',
    law_no_contract:'《劳动合同法》第十条第一款规定：建立劳动关系，应当订立书面劳动合同；第八十二条规定：用人单位自用工之日起超过一个月不满一年未与劳动者订立书面劳动合同的，应当向劳动者每月支付二倍的工资。',
    guidance_no_contract:'根据您提供的事实，您的用人单位因为【未签订书面劳动合同】而需要承担违法责任，您可以因此获得赔偿。为了评估得到赔偿的可能性和具体金额，天秤律师希望和您确认以下事实和证据信息：\n\
    1. 单位是否提出或已经给予赔偿金（及金额）？\n\
    2. 是否有工卡，工服，餐卡，工作时的聊天记录等证据能够证明您确实（曾）在单位工作过？\n\
    3. 是否有银行转账记录，微信或支付宝转账记录，支票等证据能够证明单位（曾）给您了报酬？'
}

//判断提出解除的一方
prompt.step_2 = {
    guidance_1: '请问谁提出希望解除劳动合同？'
}

//类型判断
prompt.step_3 = {
    guidance_1: '请问您希望解除劳动合同的理由是？',
    guidance_2: '请问用人单位提出解除劳动合同的理由是？',
}

//事实确认
//——————个人提出——————
prompt.step_4dot1 = {
    //单位欠薪
    law_01: '第三十八条　【劳动者单方解除劳动合同】用人单位有下列情形之一的，劳动者可以解除劳动合同：\n\
    　　（一）未按照劳动合同约定提供劳动保护或者劳动条件的；\n\
    　　（二）未及时足额支付劳动报酬的；\n\
    　　（三）未依法为劳动者缴纳社会保险费的；\n\
    　　（四）用人单位的规章制度违反法律、法规的规定，损害劳动者权益的；\n\
    　　（五）因本法第二十六条第一款规定的情形致使劳动合同无效的；\n\
    　　（六）法律、行政法规规定劳动者可以解除劳动合同的其他情形。\n\
    　　用人单位以暴力、威胁或者非法限制人身自由的手段强迫劳动者劳动的，或者用人单位违章指挥、强令冒险作业危及劳动者人身安全的，劳动者可以立即解除劳动合同，不需事先告知用人单位。',
    guidance_1: '经过判断，您遇到的劳动关系解除情况中，单位存在违法情形，因此有较大概率您可以主动解除劳动合同，并获得补偿。天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 您是否有劳动合同的原件？\n\
    2. 您单位的欠薪的时长是？\n\
    3. 您是否有一些证据能证明单位的确存在上述情形，比如打卡记录，与公司与您银行账户流水，您的社保记录等等。',

    //强制加班
    guidance_2: '经过判断，您遇到的劳动关系解除情况中，单位存在违法情形，因此有较大概率您可以主动解除劳动合同，并获得补偿。天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 您是否有劳动合同的原件？\n\
    2. 您是否明确拒绝过加班？\n\
    3. 单位是否付给您加班费？\n\
    4. 您是否有一些证据能证明单位的确存在上述情形，比如打卡记录，强制加班的制度与聊天记录，工资单等等。',

    //调岗/降薪
    guidance_3: '经过判断，您遇到的劳动关系解除情况中，单位存在违法情形，因此有较大概率您可以主动解除劳动合同，并获得补偿。天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 您是否有劳动合同的原件？\n\
    2. 单位是否与您解释过换岗/调薪的原因？\n\
    3. 单位的规章制度中是否有关于降薪的规定？\n\
    4. 降薪的幅度如何，是否低于当地的最低工资？\n\
    5. 您是否有一些证据能证明单位的确存在上述情形，比如打卡记录，强制加班的制度与聊天记录，工资单等等。',

    //合同问题
    guidance_4: '1. 您是否有劳动合同的原件？\n\
    2. 劳动合同是否有不合理的条款，如规定了过高违约赔偿，缺少薪资福利规定等？\n\
    3. 您是否和单位协商了修改劳动合同？\n\
    4. 您是否有一些证据能证明单位的确存在上述情形，比如聊天记录，工资单等等。',

    //其他原因/自愿辞职
    law_02: '《劳动合同法》第三十六条　【协商解除劳动合同】用人单位与劳动者协商一致，可以解除劳动合同。\n\
    第三十七条　【劳动者提前通知解除劳动合同】劳动者提前三十日以书面形式通知用人单位，可以解除劳动合同。劳动者在试用期内提前三日通知用人单位，可以解除劳动合同。\n\
    第三十八条　【劳动者单方解除劳动合同】用人单位有下列情形之一的，劳动者可以解除劳动合同：\n\
    　　（一）未按照劳动合同约定提供劳动保护或者劳动条件的；\n\
    　　（二）未及时足额支付劳动报酬的；\n\
    　　（三）未依法为劳动者缴纳社会保险费的；\n\
    　　（四）用人单位的规章制度违反法律、法规的规定，损害劳动者权益的；\n\
    　　（五）因本法第二十六条第一款规定的情形致使劳动合同无效的；\n\
    　　（六）法律、行政法规规定劳动者可以解除劳动合同的其他情形。\n\
    　　用人单位以暴力、威胁或者非法限制人身自由的手段强迫劳动者劳动的，或者用人单位违章指挥、强令冒险作业危及劳动者人身安全的，劳动者可以立即解除劳动合同，不需事先告知用人单位。',
    guidance_5: '经过判断，您希望解除劳动关系的理由并不符合法定单方解除劳动合同且获得赔偿的理由。您可能无法获得单位赔偿。\n\
    但为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提出或已经给予赔偿金（及金额）？\n\
    2. 您是否有劳动合同的原件？\n\
    3. 您的单位是否有变相逼迫辞职的行为，如没收电脑，关闭办公室权限，调岗调薪，强迫加班等？\n\
    4. 您的单位是否有一些违法行为？包括但不限于：（一）未按照劳动合同约定提供劳动保护或者劳动条件的；（二）未及时足额支付劳动报酬的；（三）未依法为劳动者缴纳社会保险费的；（四）用人单位的规章制度违反法律、法规的规定，损害劳动者权益的；（五）以欺诈、胁迫的手段或者乘人之危，使对方在违背真实意思的情况下订立或者变更劳动合同的；（六）以暴力、威胁或者非法限制人身自由的手段强迫劳动者劳动的，或者用人单位违章指挥、强令冒险作业危及劳动者人身安全的。'
}

//事实确认
//——————单位提出——————
prompt.step_4dot2 = {
    //绩效不合格
    law_01: '《劳动合同法》第四十条规定：有下列情形之一的，用人单位提前三十日以书面形式通知劳动者本人或者额外支付劳动者一个月工资后，可以解除劳动合同：\n\
    　　（一）劳动者患病或者非因工负伤，在规定的医疗期满后不能从事原工作，也不能从事由用人单位另行安排的工作的；\n\
    　　（二）劳动者不能胜任工作，经过培训或者调整工作岗位，仍不能胜任工作的；\n\
    　　（三）劳动合同订立时所依据的客观情况发生重大变化，致使劳动合同无法履行，经用人单位与劳动者协商，未能就变更劳动合同内容达成协议的。',
    guidance_1: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但根据法律规定，单位仍然可能有义务向您给予赔偿，为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 单位是否提出或已经给予赔偿金（及金额）？\n\
    3. 单位的绩效规定是否规定在部门规章中，是否进行了民主公示？\n\
    4. 单位在绩效低/不合格后是否进行了培训或调整岗位？\n\
    5. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    6. 您的是否掌握了一些证据能够支持上述事实（如打卡记录，绩效记录，不能胜任的通知，转岗通知等）？\n\
    7. 您是否处于不能被开除的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期，即将退休等）？',

    //工作过失
    law_02: '《劳动合同法》第三十九条规定：劳动者有下列情形之一的，用人单位可以解除劳动合同：\n\
    　　（一）在试用期间被证明不符合录用条件的；\n\
    　　（二）严重违反用人单位的规章制度的；\n\
    　　（三）严重失职，营私舞弊，给用人单位造成重大损害的；\n\
    　　（四）劳动者同时与其他用人单位建立劳动关系，对完成本单位的工作任务造成严重影响，或者经用人单位提出，拒不改正的；\n\
    　　（五）因本法第二十六条第一款第一项规定的情形致使劳动合同无效的；\n\
    　　（六）被依法追究刑事责任的。',
    guidance_2: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 您工作中的行为是否造成了公司的损失，损失大小如何？\n\
    2. 单位是否提前通知您需要解除劳动关系？\n\
    3. 单位是否提出或已经给予赔偿金（及金额）？\n\
    4. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    5. 您的单位是否掌握了能够支持解除劳动关系的证据（如工作记录、造成损失的证明等）\n\
    6. 您是否处于不能被开除的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期，即将退休等）？',

    //合同期满
    law_03: '《劳动合同法》第四十四条规定：有下列情形之一的，劳动合同终止：\n\
    　　（一）劳动合同期满的；\n\
    　　（二）劳动者开始依法享受基本养老保险待遇的；\n\
    　　（三）劳动者死亡，或者被人民法院宣告死亡或者宣告失踪的；\n\
    　　（四）用人单位被依法宣告破产的；\n\
    　　（五）用人单位被吊销营业执照、责令关闭、撤销或者用人单位决定提前解散的；\n\
    　　（六）法律、行政法规规定的其他情形。\n\
    《劳动合同法》第四十六条规定：有下列情形之一的，用人单位应当向劳动者支付经济补偿：\n\
    　　（五）除用人单位维持或者提高劳动合同约定条件续订劳动合同，劳动者不同意续订的情形外，依照本法第四十四条第一项规定终止固定期限劳动合同的；',
    guidance_3: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但根据法律规定，单位仍然可能有义务向您给予赔偿，为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 您的工作年限是？\n\
    3. 单位是否提出或已经给予赔偿金（及金额）？\n\
    4. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    5. 您的单位是否掌握了可能不利于您获得赔偿的证据（如缺勤记录，不能胜任工作的记录等）？\n\
    6. 您是否处于不能被解除劳动关系的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期，即将退休等）？',

    //单位裁员
    law_04: '《劳动合同法》第四十一条规定：有下列情形之一，需要裁减人员二十人以上或者裁减不足二十人但占企业职工总数百分之十以上的，用人单位提前三十日向工会或者全体职工说明情况，听取工会或者职工的意见后，裁减人员方案经向劳动行政部门报告，可以裁减人员：\n\
    　　（一）依照企业破产法规定进行重整的；\n\
    　　（二）生产经营发生严重困难的；\n\
    　　（三）企业转产、重大技术革新或者经营方式调整，经变更劳动合同后，仍需裁减人员的；\n\
    　　（四）其他因劳动合同订立时所依据的客观经济情况发生重大变化，致使劳动合同无法履行的。\n\
    　　裁减人员时，应当优先留用下列人员：\n\
    　　（一）与本单位订立较长期限的固定期限劳动合同的；\n\
    　　（二）与本单位订立无固定期限劳动合同的；\n\
    　　（三）家庭无其他就业人员，有需要扶养的老人或者未成年人的。\n\
    　　用人单位依照本条第一款规定裁减人员，在六个月内重新招用人员的，应当通知被裁减的人员，并在同等条件下优先招用被裁减的人员。',
    guidance_4: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但根据法律规定，单位仍然可能有义务向您给予赔偿，为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 单位是否提前30日向工会或者全体职工说明情况，听取工会或者职工的意见？\n\
    3. 单位是否提出或已经给予赔偿金（及金额）？\n\
    4. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    5. 您是否属于法律规定的需要优先留用的人员？\n\
    6. 您是否处于不能被解除劳动关系的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期，即将退休等）？',

    //身体原因
    law_05: '《劳动合同法》第四十条规定：有下列情形之一的，用人单位提前三十日以书面形式通知劳动者本人或者额外支付劳动者一个月工资后，可以解除劳动合同：\n\
    　　（一）劳动者患病或者非因工负伤，在规定的医疗期满后不能从事原工作，也不能从事由用人单位另行安排的工作的；\n\
    　　（二）劳动者不能胜任工作，经过培训或者调整工作岗位，仍不能胜任工作的；\n\
    　　（三）劳动合同订立时所依据的客观情况发生重大变化，致使劳动合同无法履行，经用人单位与劳动者协商，未能就变更劳动合同内容达成协议的。\n\
    第四十二条规定：劳动者有下列情形之一的，用人单位不得依照本法第四十条、第四十一条的规定解除劳动合同：\n\
    　　（一）从事接触职业病危害作业的劳动者未进行离岗前职业健康检查，或者疑似职业病病人在诊断或者医学观察期间的；\n\
    　　（二）在本单位患职业病或者因工负伤并被确认丧失或者部分丧失劳动能力的；\n\
    　　（三）患病或者非因工负伤，在规定的医疗期内的；\n\
    　　（四）女职工在孕期、产期、哺乳期的；\n\
    　　（五）在本单位连续工作满十五年，且距法定退休年龄不足五年的；\n\
    　　（六）法律、行政法规规定的其他情形。',
    guidance_5: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但根据法律规定，单位仍然可能有义务向您给予赔偿，为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 单位是否提出或已经给予赔偿金（及金额）？\n\
    3. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    4. 您是否患了（可能的）职业病或是否受工伤？\n\
    5. 您在（可能）患病后是否接受了病假，病假的时长是？\n\
    6. 您的身体当前或未来是否已经不能支持原来的工作？\n\
    7. 您的单位是否掌握了可能不利于您获得赔偿的证据（如缺勤记录，不能胜任工作的记录等）？\n\
    8. 您是否处于不能被解除劳动关系的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期，即将退休等）？',

    //试用期内
    guidance_6: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 您工作中的行为是否造成了公司的损失，如有，损失大小如何？\n\
    2. 单位是否向您解释了不符合录用条件的原因？\n\
    3. 单位是否提出或已经给予赔偿金（及金额）？\n\
    4. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    5. 您的单位是否掌握了能够支持解除劳动关系的证据（如工作记录、造成损失的证明等）？\n\
    6. 您是否处于不能被开除的状态中（如孕期、产期、哺乳期，工伤、职业病、哺乳期等）？',

    //单位39理由
    guidance_7: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 单位是否提出或已经给予赔偿金（及金额）？\n\
    3. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    4. 您的单位是否掌握了能够支持解除劳动关系的证据（如不符合公司相关规定的打卡、业绩和其他工作记录等）',

    //单位40理由
    guidance_8: '经过判断，您遇到的劳动关系解除纠纷中对方提出了可能合法的理由，但根据法律规定，单位仍然可能有义务向您给予赔偿，为了为您争取更多利益，天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 单位是否提出或已经给予赔偿金（及金额）？\n\
    3. 您是否有劳动合同？单位是否已经开具了劳动关系解除通知书？\n\
    4. 您的单位是否掌握了能够支持解除劳动关系的证据（如不能胜任的通知，转岗通知等）',

    //无理由/违法理由
    law_06: '《劳动合同法》第四十八条规定：用人单位违反本法规定解除或者终止劳动合同，劳动者要求继续履行劳动合同的，用人单位应当继续履行；劳动者不要求继续履行劳动合同或者劳动合同已经不能继续履行的，用人单位应当依照本法第八十七条规定支付赔偿金。',
    guidance_9: '经过判断，您遇到的劳动关系解除情况中，单位提出的解除劳动关系解除理由是违法的，因此有较大概率是违法解除。天秤律师希望和您确认以下事实和证据信息，以便为您提供精准的法律意见：\n\
    1. 单位是否提前通知您需要解除劳动关系？\n\
    2. 您是否有劳动合同的原件？单位是否已经开具了劳动关系解除通知书？\n\
    3. 您的单位是否说明了其他支持解除劳动关系的理由（如不符合公司相关规定的打卡、业绩和其他工作记录等）？'

}

prompt.step_5 = {
    //未签合同
    prompt_1: 'prompt=这是一个劳动纠纷案件，根据劳动者给出的信息，用人单位需要因为未签劳动合同而承担违约责任，因此，你需要告诉我现有的证据是否能：【证明劳动关系（曾经）存在】。需要注意的是，劳动合同没有签订完全与劳动关系是否存在无关，劳动关系是否存在应当根据是否劳动过以及是否领取过劳动报酬来判断。\n\
    以下是一个例子：“员工A以用人单位未签订劳动合同为理由，请求公司赔偿。他陈述了如下事实：单位没有与员工A签署劳动合同；员工A的银行流水显示，每月单位会定期向员工A支付人民币6000元。\n\
    员工A有每月得到公司的银行流水证明，除非用人单位能够说明转账的原因为其他用途，否则可以证明劳动关系存在；\n\
    员工A表达没有签订劳动合同，除非用人单位能够出示已经签订了劳动合同的证明，否则员工A的以未签劳动合同的理由主张的违约责任成立。\n\
    综上所述，员工A提供的证据已经足以支持事实，且事实和证据能够支持员工A的赔偿金请求。”\n\
    如果劳动关系存在，且用人单位无法举证劳动合同曾经存在，则用人单位需要承担违法责任。请向我直接说明这一点，并做简要解释，且无需考虑下方的语句。\n\
    如果现有的证据不足以支持劳动者解决纠纷，或许劳动者无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，在不足以支持的情况下，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您根据上述规则和例子，判断劳动者的诉求能否被支持，并简要说明您的选择理由。\n\
    事实&证据：',

    //单位欠薪/强制加班/降薪/劳动合同有问题
    prompt_2: 'prompt=这是一个劳动纠纷案件，劳动者给出的解除劳动关系的理由可能符合《劳动合同法》第三十八条的规定，也可能不符合，《劳动合同法》第三十八条规定，用人单位有下列情形之一的，劳动者可以解除劳动合同：\n\
    　　（一）未按照劳动合同约定提供劳动保护或者劳动条件的；\n\
    　　（二）未及时足额支付劳动报酬的；\n\
    　　（三）未依法为劳动者缴纳社会保险费的；\n\
    　　（四）用人单位的规章制度违反法律、法规的规定，损害劳动者权益的；\n\
    　　（五）因本法第二十六条第一款规定的情形致使劳动合同无效的；\n\
    　　（六）法律、行政法规规定劳动者可以解除劳动合同的其他情形。\n\
    　　用人单位以暴力、威胁或者非法限制人身自由的手段强迫劳动者劳动的，或者用人单位违章指挥、强令冒险作业危及劳动者人身安全的，劳动者可以立即解除劳动合同，不需事先告知用人单位。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果符合，则用人单位需要承担违法责任。如果不符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //绩效不合格
    prompt_3: 'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由可能符合《劳动合同法》第四十条的规定，也可能不符合，《劳动合同法》第四十条规定：有下列情形之一的，用人单位提前三十日以书面形式通知劳动者本人或者额外支付劳动者一个月工资后，可以解除劳动合同：\n\
    　　（一）劳动者患病或者非因工负伤，在规定的医疗期满后不能从事原工作，也不能从事由用人单位另行安排的工作的；\n\
    　　（二）劳动者不能胜任工作，经过培训或者调整工作岗位，仍不能胜任工作的；\n\
    　　（三）劳动合同订立时所依据的客观情况发生重大变化，致使劳动合同无法履行，经用人单位与劳动者协商，未能就变更劳动合同内容达成协议的。\n\
    业绩考核居于末位这是一种客观事实，并非劳动者的主观心态。只要有考核，就必然存在排名先后。\n\
    因此，业绩考核居于末位，不意味着严重违反用人单位的规章制度，用人单位不能以此为据单方面辞退劳动者（但存在故意或重大过失情形除外）。即使劳动者不能胜任工作，用人单位也不能据此直接予以辞退，而是应当对劳动者进行培训或者调整其工作岗位，劳动者仍不能胜任工作的，用人单位才可予以辞退。综上所述，用人单位不能根据末位淘汰制单方面辞退劳动者，否则企业将构成违法解除劳动合同，劳动者有权要求用人单位支付经济赔偿金或要求继续履行劳动合同。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //工作过失
    prompt_4: 'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由可能符合《劳动合同法》第三十九条的规定，也可能不符合，根据《劳动合同法》第三十九条的规定，用人单位有权单方解除劳动合同的具体情形包括：\n\
    （一）在试用期间被证明不符合录用条件；\n\
    （二）严重违反用人单位的规章制度；\n\
    （三）严重失职，营私舞弊，给用人单位造成重大损害；\n\
    （四）劳动者同时与其他用人单位建立劳动关系，对完成本单位的工作任务造成严重影响，或者经用人单位提出，拒不改正；\n\
    （五）因本法第二十六条第一款第一项规定的情形致使劳动合同无效；\n\
    （六）被依法追究刑事责任。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //劳动合同期满
    prompt_5: '这是一个劳动纠纷案件，劳动者给出的单位终止劳动关系的理由可能符合《劳动合同法》第三十九条的规定，也可能不符合，《劳动合同法》第四十四条规定：有下列情形之一的，劳动合同终止：\n\
    　　（一）劳动合同期满的；\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //单位裁员
    prompt_6: 'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由可能符合《劳动合同法》第四十一条的规定，也可能不符合，《劳动合同法》第四十一条规定：有下列情形之一，需要裁减人员二十人以上或者裁减不足二十人但占企业职工总数百分之十以上的，用人单位提前三十日向工会或者全体职工说明情况，听取工会或者职工的意见后，裁减人员方案经向劳动行政部门报告，可以裁减人员：\n\
    　　（一）依照企业破产法规定进行重整的；\n\
    　　（二）生产经营发生严重困难的；\n\
    　　（三）企业转产、重大技术革新或者经营方式调整，经变更劳动合同后，仍需裁减人员的；\n\
    　　（四）其他因劳动合同订立时所依据的客观经济情况发生重大变化，致使劳动合同无法履行的。\n\
    　　裁减人员时，应当优先留用下列人员：\n\
    　　（一）与本单位订立较长期限的固定期限劳动合同的；\n\
    　　（二）与本单位订立无固定期限劳动合同的；\n\
    　　（三）家庭无其他就业人员，有需要扶养的老人或者未成年人的。\n\
    　　用人单位依照本条第一款规定裁减人员，在六个月内重新招用人员的，应当通知被裁减的人员，并在同等条件下优先招用被裁减的人员。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。无需考虑下方的语句。\n\
    如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //试用期内
    prompt_7:'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由可能符合《劳动合同法》第三十九条的规定，也可能不符合，根据《劳动合同法》第三十九条的规定，用人单位有权单方解除劳动合同的具体情形包括：\n\
    （一）在试用期间被证明不符合录用条件；\n\
    （二）严重违反用人单位的规章制度；\n\
    （三）严重失职，营私舞弊，给用人单位造成重大损害；\n\
    （四）劳动者同时与其他用人单位建立劳动关系，对完成本单位的工作任务造成严重影响，或者经用人单位提出，拒不改正；\n\
    （五）因本法第二十六条第一款第一项规定的情形致使劳动合同无效；\n\
    （六）被依法追究刑事责任。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //单位39理由
    prompt_8:'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由可能符合《劳动合同法》第三十九条的规定，也可能不符合，根据《劳动合同法》第三十九条的规定，用人单位有权单方解除劳动合同的具体情形包括：\n\
    （一）在试用期间被证明不符合录用条件；\n\
    （二）严重违反用人单位的规章制度；\n\
    （三）严重失职，营私舞弊，给用人单位造成重大损害；\n\
    （四）劳动者同时与其他用人单位建立劳动关系，对完成本单位的工作任务造成严重影响，或者经用人单位提出，拒不改正；\n\
    （五）因本法第二十六条第一款第一项规定的情形致使劳动合同无效；\n\
    （六）被依法追究刑事责任。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //单位40理由
    prompt_9:'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由可能符合《劳动合同法》第四十条的规定，也可能不符合，《劳动合同法》第四十条规定：有下列情形之一的，用人单位提前三十日以书面形式通知劳动者本人或者额外支付劳动者一个月工资后，可以解除劳动合同：\n\
    　　（一）劳动者患病或者非因工负伤，在规定的医疗期满后不能从事原工作，也不能从事由用人单位另行安排的工作的；\n\
    　　（二）劳动者不能胜任工作，经过培训或者调整工作岗位，仍不能胜任工作的；\n\
    　　（三）劳动合同订立时所依据的客观情况发生重大变化，致使劳动合同无法履行，经用人单位与劳动者协商，未能就变更劳动合同内容达成协议的。\n\
    根据下方提供的事实和证据，请评估该案件中劳动者说明的解除劳动关系的理由是否符合上述法律。如果不符合，则用人单位需要承担违法责任。如果依然符合，那么现有的证据不足以支持劳动者解决纠纷，或无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

    //无理由/违法理由
    prompt_10:'prompt=这是一个劳动纠纷案件，劳动者给出的单位解除劳动关系的理由有较大概率是违法理由，因此，你需要告诉我现有的证据是否能：【证明劳动合同（曾经）存在】、【证明用人单位已经表达了劳动关系解除的意思】、【用人单位给出的解除劳动关系的理由不合法】。以下是一个例子：\n\
    “员工A希望以单位解除劳动合同理由不合法为由请求公司赔偿。他陈述了如下事实：单位与员工A签署了劳动合同；单位主动提出要解除劳动合同；单位解除劳动合同的理由是绩效不达标，不能胜任岗位；员工A从2022年9月工作到2023年5月；单位没有提前通知解除劳动合同；单位不希望支付任何赔偿金；员工A有劳动合同的文书照片和劳动关系解除通知书的原件，原件上写明了解除劳动合同的理由，理由是业绩不达标。\n\
    员工A签订了劳动合同，有劳动合同的文书照片，可以证明劳动关系存在；\n\
    员工A有劳动关系解除通知书，可以证明单位已表达清楚解除劳动合同的意识。\n\
    劳动关系解除通知书的原件上写明的业绩不达标的原因并不是法定的单位可以合法解除劳动关系的理由，进而，单位违法解除了劳动关系。\n\
    综上所述，员工A提供的证据已经足以支持事实，且事实和证据能够支持员工A提出的单位违法解除劳动关系的情况，进而支持员工A的赔偿金请求。\n\
    根据下方提供的事实和证据，请评估该案件中用人单位需要承担违法责任。无需考虑下方的语句。\n\
    如果现有的证据不足以支持劳动者解决纠纷，或许劳动者无法获得赔偿，但是，在劳动纠纷解决过程中，如果单位主张自己解除劳动合同的理由是合法的，那么单位应当承担举证责任。因此，在不足以支持的情况下，应当建议劳动者考虑以下解决办法：\n\
    寻求进一步收集证据的方式，如调查证人陈述或收集相关文件；\n\
    主张用人单位没有能证明有权解除合同的证据，或证据不合法。\n\
    主张用人单位可能有其他违法情况。\n\
    请您选择某种或某几种解决办法，并简要说明您的选择理由。\n\
    事实&证据：',

}

export default prompt