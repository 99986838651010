import llm from './be-llm'; //llm模块
import cdb from './be-cbd' //云数据库模块
import fe from './fe-utils' //前端功能模块
import guide from './fe-guide'//用户引导模块


const HiLaw = new Object();

HiLaw.index_1dot2 = (event,this_contract,this_termination_reason,this_set_index,this_law,this_text,callback) => {
    event.set_case(prevState => ({ ...prevState, contract: this_contract,termination_reason: this_termination_reason })) 
    event.setIndex(this_set_index)
    fe.aiReply(this_law,this_text,event)
    callback()
}

HiLaw.index_2 = (event,this_proposing_party,this_set_index,this_law,this_text,this_addQuickReplies)=>{
    event.set_case(prevState => ({ ...prevState, proposing_party: this_proposing_party})) 
    event.setIndex(event.step_index + this_set_index) 
    //fe.aiReply(this_law,this_text,event)
    //fe.addQR([event.defaultQR, event.setQR],this_addQuickReplies)
}

HiLaw.index_3 = (event,this_termination_reason,this_law,this_text) => {
    event.set_case(prevState => ({ ...prevState, termination_reason: this_termination_reason})) 
    event.setIndex(event.step_index + 1)
    fe.aiReply(this_law,this_text,event)
}

HiLaw.index_3_llm = (event,proposing_party,callback) => {
    let formatted_messages_session = fe.format_messages(event.val,event.messages,9)
    cdb.classify(event.doc_id,formatted_messages_session,function(messages){
        llm.classify(event.doc_id,proposing_party,messages)
    },function(llm_reply){
        fe.aiReply('null',llm_reply,event)
    },function(output){
        console.log('classify_output:',output)
        let output_index = output.match(/\d+/g).map(Number);
        console.log('output_index:',output_index[0])
        callback(output_index)
    }) 
}

HiLaw.index_4 = (event,messages_index,termination_reason,this_promp,this_law,this_context) => {
    let formatted_messages_session = fe.format_messages(event.val,event.messages,messages_index)
    cdb.confirm_1(event,()=>{
        console.log('llm调用参数：',event.doc_id,formatted_messages_session,termination_reason)
        llm.confirm_1(event.doc_id,formatted_messages_session,termination_reason)
    },(llm_reply,finish)=>{
        if(finish){
            fe.aiReply('null',llm_reply,event,true)
        }else{
            fe.aiReply('null',llm_reply,event)
        }
    },(case_JSON)=>{
        event.setIndex(5)
        console.log('进入证据判断，index：',event.step_index)
        cdb.confirm_2(event,()=>{
            llm.confirm_2(event.doc_id,this_promp,this_law,this_context,case_JSON)
        },(llm_reply)=>{
            fe.aiReply('null',llm_reply,event,true)
        },(llm_reply)=>{
            let formatted_messages = []
            let mesg = new Object()
            mesg.role="assistant";
            mesg.content=llm_reply;
            formatted_messages.push(mesg)
            //
            cdb.judge(event,() => {
                llm.judge(event.doc_id,formatted_messages)
            },() => {
                // event.setIndex(6)
                guide.pay_type(event)
            },(this_text) => {
                fe.aiReply('null',this_text,event)
            })
        })
    }) 
}


export default HiLaw